import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '../form.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-scholarship-form',
  templateUrl: './scholarship-form.component.html',
  styleUrls: ['./scholarship-form.component.scss']
})
export class ScholarshipFormComponent implements OnInit {
  fileSizeError: boolean = false;
  scholarshipForm: FormGroup;
  selectedStandard: string | null = null;
  years: string[] = [];


constructor(private fb: FormBuilder, private router: Router, private formService: FormService,private http:HttpClient) {
  this.scholarshipForm = this.fb.group({
    name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
    number: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    email: ['', [Validators.required, Validators.email]],
    school: ['', Validators.required],
    std: ['', Validators.required],
    year: ['', Validators.required],
    branch: ['', Validators.required],
    file1: [''],
    aadhar: ['', [Validators.required, Validators.pattern(/^\d{12}$/)]],
    dateOfBirth: ['', Validators.required],
    gender: ['', Validators.required],
    state: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
    dist: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
    taluka: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
    city: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$')]],
    address: ['', Validators.required],
    aadharadd: ['', Validators.required],
    referralCode: [''] 
  });


  this.scholarshipForm.get('std')?.valueChanges.subscribe(value => {
    this.onStandardChange(value);
  });
}
  get f() { return this.scholarshipForm.controls; }

    ngOnInit(): void {
    const savedFormData = this.formService.loadFormData();
    if (savedFormData) {
      this.scholarshipForm.setValue(savedFormData);
    }
  }

  onSave(): void {
    if (this.scholarshipForm.valid) {
      this.formService.saveFormData(this.scholarshipForm);
      alert('Form Saved Successfully.');
      console.log('Form data is saved',  this.scholarshipForm.value)
    } else {
      // alert('All fields are required.');
    }
  }
  onFileChange(event: any): void {
    // if (event.target.files.length > 0) {
    //   const file = event.target.files[0];
    //   this.formService.setRegistrationFile(file);
    //   this.scholarshipForm.patchValue({ file1: file });
    // }
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      // Check file size
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB < 0 || fileSizeInKB > 140) {
        this.fileSizeError = true;
        
        // You may also reset the form control here if needed
        // this.scholarshipForm.get('file1')?.reset(); 
      } else {
        this.fileSizeError = false;
        this.formService.setRegistrationFile(file);
        this.scholarshipForm.patchValue({ file1: file });
        // Proceed with other logic if size is acceptable
      }
    }
  }
  // onNext(): void {
  //   if (this.scholarshipForm.valid) {

  //     console.log("valid scholership data"+this.scholarshipForm.value);
  //     console.log("valid save data"+this.scholarshipForm)
  //     this.formService.saveFormData(this.scholarshipForm);
  //     this.formService.setRegistrationData(this.scholarshipForm.value);
  //     this.router.navigate(['/term-condition']);
  //     console.log('Form data is saved',  this.scholarshipForm.value)
  //   } else {
  //     // alert('All fields are required.');
  //   }
  // }
  onNext(): void {
    if (this.scholarshipForm.valid) {
      const email = this.scholarshipForm.value.email;
      
      // Make the HTTP request to check if the email exists
      this.http.post('https://vstechhorizon.com:3006/checkEmail', this.scholarshipForm.value).subscribe(
        (response: any) => {
          // Email is available, proceed to save the form data
          this.formService.saveFormData(this.scholarshipForm.value);
          this.formService.setRegistrationData(this.scholarshipForm.value);
          this.router.navigate(['/term-condition']);
          console.log('Form data is saved', this.scholarshipForm.value);
        },
        (error: HttpErrorResponse) => {
        
        alert('Application with this email already exists.');
          this.handleHttpError(error)
        }
  
      );
    } else {
      // Form is invalid, alert the user or handle as needed
      alert('Please fill out all required fields correctly.');
    }
  }

  private handleHttpError(error: HttpErrorResponse): void {
    if (error.status === 400 && error.error.message === 'Email already exists') {
          // Handle case where email already exists
          
        } else {
          // Handle other errors
          console.error('Error checking email', error);
          alert('Error checking email. Please try again.');
        }
}



  onSubmit(): void {
    if (this.scholarshipForm.valid) {
      // alert('Form Submitted Successfully.');
      // console.log('Form Submitted', this.scholarshipForm.value);
      // this.formService.saveFormData(this.scholarshipForm);
    } else {
      alert('All fields are required.');
      console.log('Form is invalid');
    }
  }

  clearFormOnReload(): void {
    this.formService.clearFormData();
  }
  onStandardChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const standard = target.value;
    this.selectedStandard = standard;
    if (standard === 'diploma') {
      this.years = ['1st Year', '2nd Year', '3rd Year'];
    } else if (standard === 'degree') {
      this.years = ['1st Year', '2nd Year', '3rd Year', '4th Year'];
    } else {
      this.years = [];
    }
  }
}