<app-main-header></app-main-header>
<div id="main-container">
    
    <h1>Terms and Conditions</h1>

    <p><strong>Last updated: July 13, 2024</strong></p>
    
    <h2>Introduction</h2>
    
    <p>Welcome to VS Tech Horizon Private Limited ("Company," "we," "our," or "us")! These Terms and Conditions govern your use of the VS Tech Horizon website and services accessible at <a href="https://vstechhorizon.com/" target="_blank" rel="noopener noreferrer">https://vstechhorizon.com/</a> ("Service"). By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you disagree with any part of these Terms, please refrain from using our Service.</p>
    
    <h2>Intellectual Property</h2>
    
    <p>All rights to the Service's exclusive content, features, and functionality belong to VS Tech Horizon Private Limited and its licensors. The Service is protected by copyright, trademark, and other laws. Use of our trademarks requires prior written consent from VS Tech Horizon Private Limited.</p>
    
    <h2>Privacy Policy</h2>
    
    <p>Protecting your privacy is paramount at VS Tech Horizon Private Limited. Please review our <a href="/privacyandpolicy">Privacy Policy</a> to understand how we handle your personal information when you use our services.</p>
    
    <h2>Prohibited Activities</h2>
    
    <ul>
        <li>Violating any applicable laws or regulations.</li>
        <li>Uploading, transmitting, or storing illegal content.</li>
        <li>Attempting unauthorized access to computer systems or networks.</li>
        <li>Launching malicious attacks disrupting online services.</li>
        <li>Using unauthorized software on company devices.</li>
        <li>Sharing confidential information without authorization.</li>
        <li>Engaging in harassment, discrimination, or defamation.</li>
        <li>Copying, modifying, or distributing VS Tech Horizon's intellectual property.</li>
        <li>Posting negative or defamatory content about VS Tech Horizon.</li>
        <li>Misusing company resources for excessive personal use.</li>
        <li>Attempting to reverse engineer software or technology provided by VS Tech Horizon.</li>
    </ul>
    
    <h2>Modification of Terms</h2>
    
    <p>VS Tech Horizon Private Limited reserves the right to update these Terms at any time. Your continued use of the website after any modifications constitutes acceptance of the updated Terms.</p>
    
    <h2>Governing Law</h2>
    
    <p>These Terms and any disputes arising from them are governed by the laws of India. Any disputes will be exclusively resolved by the courts of India.</p>
    
    <h2>Modifications to the Service</h2>
    
    <p>We reserve the right to modify or discontinue the Service at any time without prior notice. We are not liable for any part or entirety of the Service becoming temporarily or permanently inaccessible.</p>
    
    <h2>Limitation of Liability</h2>
    
    <p>VS Tech Horizon Private Limited, its officers, directors, and employees are not liable for any issues arising from your use of the Service, whether under contract or otherwise, including indirect, consequential, or special liabilities.</p>
    
    <h2>Termination</h2>
    
    <p>We reserve the right to suspend or terminate your account and access to the Service at our discretion, without prior notice or liability, for any reason.</p>
    
    <h2>Exclusion of Links</h2>
    
    <p>We do not guarantee the accuracy, completeness, or continuous availability of information on our website. Offensive links may be reported but we are not obligated to respond or take action.</p>
    
    <h2>Disclaimer</h2>
    
    <p>Information on VS Tech Horizon's platform is provided for general informational purposes without assurances of accuracy or completeness. Users assume risk and we disclaim liability for damages.</p>
    
    <h2>Copyright Policy</h2>
    
    <p>All original content created by VS Tech Horizon is owned by the company. Users must respect copyright laws. Contact us at <a href="mailto:vstechhorizon@gmail.com">vstechhorizon@gmail.com </a> for copyright inquiries or violations.</p>
    
    <h2>Contact Us</h2>
    
    <p>For inquiries, feedback, comments, or complaints, please contact us at:</p>
    <!-- <ul>
        <li>Email: [Your contact email address]</li>
        <li>Website: [Your website contact page URL]</li>
        <li>Phone: [Your contact phone number]</li>
        
    </ul> -->
    <p>VS Tech Horizon Private Limited,<br>
        Plot No.13 Gat No.574 Sam Fransisco Park, Matosh, Haveli, Kesnand Pune-412207 <br>
        Contact Number:+91 9325360357 <br>
        Email: vstechhorizon@gmail.com</p> <br>
    
</div>

<app-footer></app-footer>