
 <app-main-header [class.disabled]="isPopDisabled"></app-main-header>
 <div class="main" (click)="hidePopup()">
   <div class="popup" *ngIf="showPopup">
     <video src="../../assets/img/Scholership exam poster1.mp4" (click)="hidePopup()" autoplay loop></video>
   </div>
   <div class="container" [class.disabled]="isPopDisabled">
     <h1>Exams Details</h1>
     <p> <b>Secure your future with the VS Tech Horizon Scholarship Exam! Win up to ₹100,000, earn certificates, receive lifetime career guidance, and Students with >75%: Scholar Access with career guidance and enhanced resources. Register now!</b>
      <br>
      <br>
       <b>Contact Information for Assistance: </b>
        <br>
        <br>
       If you encounter any difficulties or have questions while filling out the form, please feel free to reach out to us for assistance: <br>
       <br>
       <b>. Name: </b>VS Tech Horizon Scholarship Support Team <br>
       <b>. Email:</b> vstechhorizonscholarship@gmail.com <br>
       <b>. Phone Number:</b> +91 9325360357 <br>
       <b>. Availability:</b> 8 AM to 10 PM (All Day) <br>
       <br>
       We are here to help ensure a smooth registration process. Please don't hesitate to contact us if you need support .<br>
     </p>
     <button id="btn" class="btn btn-primary" [routerLink]="'/scholarship-form'">Apply Now</button>
   </div>
 </div>
 <app-chatbot [class.disabled]="isPopDisabled"></app-chatbot>
<app-whatsapp [class.disabled]="isPopDisabled"></app-whatsapp>
<app-footer [class.disabled]="isPopDisabled"></app-footer>