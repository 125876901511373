import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-steam-form',
  templateUrl: './steam-form.component.html',
  styleUrls: ['./steam-form.component.scss']
})
export class SteamFormComponent implements OnInit {
  steamForm: FormGroup;
  amount: any;
  showPaymentSection = false;
  constructor(private fb: FormBuilder, private paymentService: PaymentService, private route: ActivatedRoute, private http: HttpClient, private routers: Router, private dataService: DataService) {
    this.steamForm = this.fb.group({
      name: ['', Validators.required],
      sname: ['', Validators.required],
      std: ['', Validators.required],
      number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      email: ['', [Validators.required, Validators.email]],
      dateOfBirth: ['', Validators.required],
      paymentId:['']
    });
  }

  onSubmit() {
    if (this.steamForm.valid) {
      // console.log('Form Submitted!', this.steamForm.value);
      this.http.post("https://vstechhorizon.com:3006/savesteamregister",this.steamForm.value).subscribe(
        (responce)=>{
         window.alert('Form Submitted Successfully!!');
         this.steamForm.reset();
        this.routers.navigateByUrl("/");
        },
        (error)=>{
          window.alert("Form Submission Failed");
        }
      );
      
    } else {
      console.log('Form is not valid');
    }
  }
  ngOnInit(): void {
    const navigation = this.routers.getCurrentNavigation();
    if (navigation?.extras.state?.['amount']) {
      this.amount = navigation.extras.state['amount'];
    } else {
      // Fall back to service if no amount in navigation state
      this.amount = this.dataService.getAmount();
    }
    if (!this.amount) {
      window.alert("Select Valid Plan")
    }
  }

  goToPaymentSection(): void {
    if (this.steamForm.valid) {
      this.showPaymentSection = true;
    } else {
      this.steamForm.markAllAsTouched();
    }
  }
  pay() {
    // const amount = 1; // Amount in INR
    const currency = 'INR';

    this.paymentService.createOrder(this.amount, currency).subscribe(
      (order:any) => {
        this.paymentService.initiatePayment(order.id, this.amount, currency,this.steamForm.value.name,this.steamForm.value.email,this.steamForm.value.number, this.paymentResponseHandler.bind(this));
      },
      (error:any) => {
        console.error('Order creation failed', error);
      }
    );
  }

  paymentResponseHandler(response: any) {
    this.steamForm.value.paymentId=response.razorpay_payment_id;
    const transactionId = response.transactionId; // Example key for Transaction ID
    console.log(`Payment successful with Transaction ID: ${transactionId}`);
    //   console.log('Payment successful', response);
    console.log('Payment successful', response);
    this.steamForm.value.paymentId=response.razorpay_payment_id;
    const paymentId = response.razorpay_payment_id;
    const orderId = response.razorpay_order_id;
    const signature = response.razorpay_signature;

    console.log('Payment ID:', paymentId);
    console.log('Order ID:', orderId);
    console.log('Signature:', signature);

    window.alert("Payment Succesfully Done ");
    this.onSubmit(); 

    // Handle the response, such as updating the order status in your backend
  }
}
