<app-main-header></app-main-header>
<div class="cancellation-policy">
    <h1>VS Tech Horizon Cancellation Policy</h1>
    <h2>Introduction</h2>
    <p>This cancellation policy outlines the terms and conditions under which payments for any projects or programs organized by VS Tech Horizon are processed via the Razorpay payment gateway.</p>
  
    <h2>1. Cancellation Policy</h2>
    <p> <strong>Finality of Payments:</strong>  All payments made towards registrations for any projects or programs are final and non-refundable.</p>
    <p><strong>Cancellation by Participant:</strong>  Once the registration fee is paid, cancellations and requests for refunds by participants will not be entertained under any circumstances.</p>
  
    <h2>2. Program/Project Cancellation by VS Tech Horizon</h2>
    <ul>
        <li>
    <p> <strong>Company-Initiated Cancellations:</strong>  In the unlikely event that a project or program is cancelled by us due to unforeseen circumstances, a full refund will be issued.</p>
</li>
<li>
    <strong>Refund Process:</strong>
    <ul>
      <li>If possible, refunds will be processed automatically back to the original payment method used during registration via Razorpay.</li>
      <li>If an automatic refund is not possible, you will receive an email with further instructions to facilitate the refund. In such cases, the refund will be processed after deducting Razorpay's processing charges.</li>
    </ul>
    </li>
    </ul>
    <h2>3. Contact Information</h2>
    <p>For any queries or concerns regarding this cancellation policy, please contact our support team:</p>
    <p>VS Tech Horizon</p>
    <p>Email: <a href="mailto:support@vstechhorizon.com">support@vstechhorizon.com</a></p>
    <p>Phone: +91 9325360357</p>
  
    <h2>4. Amendments</h2>
    <p>VS Tech Horizon reserves the right to amend or modify this cancellation policy at any time. Participants will be notified of any changes via email or updates on our website.</p>
    <p>By registering for any projects or programs organized by VS Tech Horizon, you acknowledge that you have read, understood, and agree to this cancellation policy.</p>
  </div>
  <app-footer></app-footer>