<!-- 
<p>hii</p>
<div class="course-container">
  <div class="course-header">
    🌟 Enroll in the STEAM CONNECT Course! 🌟
  </div>
  <div class="course-content">
    <p>Join our immersive 3-month STEAM CONNECT course, designed to explore the fascinating realms of Science,
      Technology, Engineering, Arts, and Mathematics (STEAM). For a nominal registration fee of ₹300, students will
      participate in hands-on projects every Saturday and Sunday, for two hours each session.</p>
    <ul class="course-highlights">
      <li><strong>Duration:</strong> 3 months</li>
      <li><strong>Schedule:</strong> Saturdays and Sundays, 2 hours per session</li>
      <li><strong>Cost:</strong> ₹300 registration fee</li>
      <li><strong>Benefits:</strong> Practical skills, certificates, and enhanced problem-solving abilities</li>
    </ul>
    <p>Don’t miss this opportunity to inspire creativity, foster innovation, and empower the next generation of leaders
      and thinkers. Register now and be a part of the STEAM revolution!</p>
  </div>
  <div class="course-footer">
    <button class="register-button">Register Now</button>
  </div>
</div> -->

<!-- 

<div class="course-card">
    <div class="course-header">
      🌟 Enroll in the STEAM CONNECT Course! 🌟
    </div>
    <div class="course-body">
      <p>
        Join our immersive 3-month STEAM CONNECT course, designed to explore the fascinating realms of Science, Technology, Engineering, Arts, and Mathematics (STEAM). For a nominal registration fee of ₹300, students will participate in hands-on projects every Saturday and Sunday, for two hours each session.
      </p>
      <div class="course-details">
        <ul>
          <li><strong>Duration:</strong> 3 months</li>
          <li><strong>Schedule:</strong> Saturdays and Sundays, 2 hours per session</li>
          <li><strong>Cost:</strong> ₹300 registration fee</li>
          <li><strong>Benefits:</strong> Practical skills, certificates, and enhanced problem-solving abilities</li>
        </ul>
      </div>
      <p>
        Don’t miss this opportunity to inspire creativity, foster innovation, and empower the next generation of leaders and thinkers. Register now and be a part of the STEAM revolution!
      </p>
    </div>
    <div class="course-footer">
      <button class="register-button">Register Now</button>
    </div>
  </div>
   -->
<!-- 

   <div class="course-card">
    <div class="course-header">
      🌟 Enroll in the STEAM CONNECT Course! 🌟
    </div>
    <div class="course-body">
      <p class="course-description">
        Join our immersive 3-month STEAM CONNECT course, designed to explore the fascinating realms of Science, Technology, Engineering, Arts, and Mathematics (STEAM). For a nominal registration fee of ₹300, students will participate in hands-on projects every Saturday and Sunday, for two hours each session.
      </p>
      <div class="course-details">
        <ul>
          <li><strong>Duration:</strong> 3 months</li>
          <li><strong>Schedule:</strong> Saturdays and Sundays, 2 hours per session</li>
          <li><strong>Cost:</strong> ₹300 registration fee</li>
          <li><strong>Benefits:</strong> Practical skills, certificates, and enhanced problem-solving abilities</li>
        </ul>
      </div>
      <p class="course-footer">
        Don’t miss this opportunity to inspire creativity, foster innovation, and empower the next generation of leaders and thinkers. Register now and be a part of the STEAM revolution!
      </p>
    </div>
    <div class="course-footer">
      <button class="register-button">Register Now</button>
    </div>
  </div>
   -->

<!-- 
   <div class="course-container">
    <div class="course-header">
      🌟 Enroll in the STEAM CONNECT Course! 🌟
    </div>
    <div class="course-body">
      <p class="course-description">
        Join our immersive 3-month STEAM CONNECT course, designed to explore the fascinating realms of Science, Technology, Engineering, Arts, and Mathematics (STEAM). For a nominal registration fee of ₹300, students will participate in hands-on projects every Saturday and Sunday, for two hours each session.
      </p>
      <div class="course-details">
        <div class="course-detail-item"><strong>Duration:</strong> 3 months</div>
        <div class="course-detail-item"><strong>Schedule:</strong> Saturdays and Sundays, 2 hours per session</div>
        <div class="course-detail-item"><strong>Cost:</strong> ₹300 registration fee</div>
        <div class="course-detail-item"><strong>Benefits:</strong> Practical skills, certificates, and enhanced problem-solving abilities</div>
      </div>
      <p class="course-footer">
        Don’t miss this opportunity to inspire creativity, foster innovation, and empower the next generation of leaders and thinkers. Register now and be a part of the STEAM revolution!
      </p>
    </div>
    <div class="course-footer">
      <button class="register-button">Register Now</button>
    </div>
  </div>
   -->

<app-main-header></app-main-header>
   <div class="course-container">
    <div class="course-header">
      🌟 Enroll in the STEAM Connect Course! 🌟
    </div>
    <br>
    <div class="course-body">
      <p class="course-description">
        Join our immersive 3-month STEAM CONNECT course, designed to explore the fascinating realms of Science, Technology, Engineering, Arts, and Mathematics (STEAM). For a nominal registration fee of ₹300, students will participate in hands-on projects every Saturday and Sunday, for two hours each session.
      </p>
      <br>
      <div class="course-details">
        <div class="course-detail-item"><strong>Duration:</strong> 3 months</div>
        <div class="course-detail-item"><strong>Schedule:</strong> Saturdays and Sundays, 2 hours per session</div>
        <div class="course-detail-item"><strong>Cost:</strong> ₹330 registration fee</div>
        <div class="course-detail-item"><strong>Benefits:</strong> Practical skills, certificates, and enhanced problem-solving abilities</div>
      </div>
      <p class="course-footer">
        Don’t miss this opportunity to inspire creativity, foster innovation, and empower the next generation of leaders and thinkers. Register now and be a part of the STEAM revolution!
      </p>
    </div>
    <div class="course-footer">
      <button class="register-button"  (click)="applyNow()" >Register Now</button>
    </div>
  </div>
  <app-footer></app-footer>