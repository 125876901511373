<app-main-header></app-main-header>
  <div class="container">
    <h2>Course Application</h2>
    <form [formGroup]="applicationForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="name">Name</label>
        <input id="name" type="text" placeholder="Full Name" formControlName="name" class="form-control">
        <div *ngIf="applicationForm.get('name')?.invalid && applicationForm.get('name')?.touched" class="text-danger">
          Name is required.
        </div>
      </div>
  
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" type="email" placeholder="Email ID" formControlName="email" class="form-control">
        <div *ngIf="applicationForm.get('email')?.invalid && applicationForm.get('email')?.touched" class="text-danger">
          Enter a valid email.
        </div>
      </div>
  
      <div class="form-group">
        <label for="phone">Phone</label>
        <input id="phone" type="text" placeholder="Phone No." formControlName="phone" class="form-control">
        <div *ngIf="applicationForm.get('phone')?.invalid && applicationForm.get('phone')?.touched" class="text-danger">
          Enter a valid phone number.
        </div>
      </div>

      <div class="form-group">
        <label for="standard">Standard</label>
        <select id="standard" formControlName="standard" class="form-control">
          <option value="">Select your standard</option>
          <option *ngFor="let standard of standard" [value]="standard">{{ standard }}</option>
        </select>
        <div *ngIf="applicationForm.get('standard')?.invalid && applicationForm.get('standard')?.touched" class="text-danger">
          Standard selection is required.
        </div>
      </div>
  
      <div class="form-group">
        <label for="subject">Subject</label>
        <mat-form-field class="full-width">
          <mat-select formControlName="subject" multiple>
            <mat-option *ngFor="let subject of availableSubject" [value]="subject">{{subject}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="applicationForm.get('subject')?.invalid " class="text-danger"> 
          Please select at least one subject.
        </div>
      </div>
  
      <button type="submit" class="btn-submit-course" [disabled]="applicationForm.invalid">Submit</button>
    </form>
  </div>
  <app-footer></app-footer>
  <br><br><br><br><br>
  