<!-- <section class="pt-8 pb-8">
    <div class="container">
      <div class="row justify-content-center ">
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <div id="carouselExampleIndicators3" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-4 mb-3 cards" >
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 1</h4>
                        <p class="card-text text-center">"This is some handwritten feedback from a user."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 2</h4>
                        <p class="card-text text-center">"Another example of user handwriting feedback."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 3</h4>
                        <p class="card-text text-center">"More feedback from a different user."</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 4</h4>
                        <p class="card-text text-center">"Yet another piece of user feedback in handwriting."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 5</h4>
                        <p class="card-text text-center">"More feedback written by a user."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 6</h4>
                        <p class="card-text text-center">"Handwriting feedback from another user."</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a class="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
              <button class="scroll-btn prev">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                  <path fill="none" d="M0 0h24v24H0z"/>
                </svg>
              </button>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
              <button class="scroll-btn next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
                  <path fill="none" d="M0 0h24v24H0z"/>
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> -->

 <!-- <section class="pt-8 pb-8">
    <div class="container">
      <div class="row justify-content-center"></div>
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <div id="carouselExampleIndicators3" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-4 mb-3 cards">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 1</h4>
                        <p class="card-text text-center">"This is some handwritten feedback from a user."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 2</h4>
                        <p class="card-text text-center">"Another example of user handwriting feedback."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 3</h4>
                        <p class="card-text text-center">"More feedback from a different user."</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 4</h4>
                        <p class="card-text text-center">"Yet another piece of user feedback in handwriting."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 5</h4>
                        <p class="card-text text-center">"More feedback written by a user."</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="card feedback-card">
                      <div class="card-body">
                        <h4 class="card-title text-center">User Feedback 6</h4>
                        <p class="card-text text-center">"Handwriting feedback from another user."</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> 
  
  
 -->
 <section class="pt-5 pb-5">
  <br>
  <div class="container">
    <div class="row justify-content-center">
    </div>
    <div class="row">
      <div class="col-12 d-flex align-items-center">
        <div id="carouselExampleIndicators3" class="carousel slide carousel-fade" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-4 mb-3 cards">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><b>Sumit Sonkable</b></h4>
                      <p class="card-text"><b>"I learned a lot by actually doing the projects, and the kits had everything we needed."</b></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><b>Sahil Dhawane</b></h4>
                      <p class="card-text"><b>"Having guidance from the instructor made the project less stressful. I enjoyed the hands-on experience."</b></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><b>Ashwini Ahire</b></h4>
                      <p class="card-text"><b>"The demonstration cleared up a lot of confusion, and the instructor was excellent."</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><b>Priya Lole</b></h4>
                      <p class="card-text"><b>"I finally understand how PCBs work. The hands-on approach with the kits was super helpful."</b></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><b>Kanha Jawake</b></h4>
                      <p class="card-text"><b>"The help we got fixed our problems quickly. It was fun and easy."</b></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title"><b>Vedant Dalve</b></h4>
                      <p class="card-text"><b>"Working on real projects made me feel more confident.The session was interesting and helpful."</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Previous and Next buttons -->
          <a class="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
            <button class="scroll-btn prev">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                <path fill="none" d="M0 0h24v24H0z" />
              </svg>
            </button>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
            <button class="scroll-btn next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                <path fill="none" d="M0 0h24v24H0z" />
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  </section>