import { Component } from '@angular/core';
import { ContentProtectionService } from './content-protection.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vsWeb';
  constructor(private contentProtectionService: ContentProtectionService) {
    // Clear the form data on page load/reload
    localStorage.removeItem('scholarshipFormData');
}

ngOnInit() {
  this.contentProtectionService.disableTextSelection();
  }
}

