import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageProtectionService {

  constructor() {}

  // Disable right-click on images
  disableRightClick(): void {
    document.addEventListener('contextmenu', function(event) {
      if ((event.target as HTMLElement).tagName === 'IMG') {
        event.preventDefault();
      }
    });
  }

  // Add watermark overlay to images
  addWatermark(image: HTMLElement): void {
    const watermark = document.createElement('div');
    watermark.classList.add('watermark-overlay');
    watermark.textContent = 'Watermark'; // Customize watermark text

    // Append watermark overlay
    image.parentNode?.appendChild(watermark);
    watermark.style.position = 'absolute';
    watermark.style.top = '50%';
    watermark.style.left = '50%';
    watermark.style.transform = 'translate(-50%, -50%)';
    watermark.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
    watermark.style.padding = '10px';
    watermark.style.borderRadius = '5px';
    watermark.style.pointerEvents = 'none'; // Ensures clicks pass through the overlay
  }
}
