

<div class="chat-icon" (click)="toggleChatInterface()">
  <i >
    <img src="../../assets/img/bot_11306053.png" alt="">
  </i>
</div>

<div class="chat-container" *ngIf="showChatInterface">
  <div id="chat">
    <div id="chatHeader">Chat Bot</div>
    <div id="messagesContainer" #messagesContainer>
      <div *ngFor="let message of messages">
        <div [ngClass]="{'user-message': message.user, 'bot-message': !message.user}" class="message">
          {{ message.text }}
        </div>
      </div>
    </div>
    <div id="inputContainer">
      <!-- <input [(ngModel)]="newMessage" id="messageInput" placeholder="Type a message..."> -->
      <input [(ngModel)]="newMessage" id="messageInput" placeholder="Type a message..." (keydown.enter)="sendMessage()">
      <button (click)="sendMessage()" id="sendButton">Send</button>
    </div>
  </div>
</div>