// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-robotics-project',
//   templateUrl: './robotics-project.component.html',
//   styleUrls: ['./robotics-project.component.scss']
// })
// export class RoboticsProjectComponent {

// }


import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-robotics-project',
  templateUrl: './robotics-project.component.html',
  styleUrls: ['./robotics-project.component.scss']
})
export class RoboticsProjectComponent {
  constructor(private router: Router) { }
  courses = [
    {
      title: 'Line Following Robot Car',
      imgSrc: 'assets/robotics projects/Line Following Robot Car.jpeg',
      technology: "Arduino, IR Sensors.",
      fragment: 'line-folllowing-robot-car'
    },
    {
      title: 'Object Sorting Robot using Computer Vision',
      imgSrc: 'assets/robotics projects/Object Sorting Robot using Computer Vision.jpeg',
      technology: "Raspberry Pi,Camera Module, OpenCV",
      showInfo: false,
      fragment: 'object-sorting-robot'
    },
    {
      title: 'An automated fire extinguishing system',
      imgSrc: 'assets/robotics projects/An automated fire extinguishing system.jpg',
      technology: "Arduino, Smoke and Heat Sensors, Solenoid Valve",
      fragment: 'an-automated-fire'
    },
    {
      title: 'An automated metal detector robot',
      imgSrc: 'assets/robotics projects/An automated metal detector robot.jpg',
      technology: "Arduino, Metal Detector Sensor",
      fragment: 'an-automated-metal'
    },
    {
      title: 'A microcontroller-based multi-function solar tracking system',
      imgSrc: 'assets/robotics projects/A microcontroller-based multi-function solar tracking system.jpg',
      technology: "Arduino, Light Dependent Resistors (LDRs), Stepper Motors.",
      fragment: 'microcontroller-based-multi-function'
    },
    {
      title: 'Developing an Intelligent Waste Sorting System with Robotic Arm A Step towards Green Environment',
      imgSrc: 'assets/robotics projects/Developing an Intelligent Waste Sorting System with Robotic Arm A Step towards Green Environment.jpg',
      technology: "Raspberry Pi, Camera Module, Robotic Arm",
      fragment: 'developing-an-intelligent-waste-sorting-system'
    },
    {
      title: 'Designing and Implementation of a Wireless Gesture Controlled Robot',
      imgSrc: 'assets/robotics projects/Designing and Implementation of a Wireless Gesture Controlled Robot for Disabled and Elderly People.jpg',
      technology: "Arduino, Accelerometer and Gyroscope Sensor, Wireless Communication Module.",
      fragment: 'designing-and-implementation'
    },
    {
      title: 'Obstacle Avoidance Robotic Vehicle Using Ultrasonic Sensor',
      imgSrc: 'assets/robotics projects/Obstacle Avoidance Robotic Vehicle Using Ultrasonic Sensor.jpg',
      technology: "Arduino, Ultrasonic Sensors.",
      fragment: 'obstacle-avoidance-robotic-vehicle'
    },
    {
      title: 'Remote Vehicle Control Through Cell Phone Using DTMF',
      imgSrc: 'assets/robotics projects/Remote Vehicle Control Through Cell Phone Using DTMF.jpg',
      technology: "Arduino, DTMF Decoder Module.",
      fragment: 'remote-vehicle-control'
    },
    {
      title: 'IR Remote Controlled Robot using Arduino',
      imgSrc: 'assets/robotics projects/TV Remote Controlled Robot using Arduino.jpg',
      technology: "Arduino, IR Receiver Module.",
      fragment: 'ir-remote-controller'
    },
    
  ];


  toggleInfo(course: any) {
    course.showInfo = !course.showInfo;
  }




  showPopup = false;
  name = '';
  message = '';


  showPopupBox() {
    this.showPopup = true;
    setTimeout(() => {
      const popupElement = document.querySelector('.popup');
      if (popupElement) {
        popupElement.classList.add('show');
      }
    }, 15);
  }

  hidePopup() {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.remove('show');
    }
    setTimeout(() => {
      this.showPopup = false;
    }, 500);
  }

  navigateToFragment(course: any) {
    this.router.navigate(['/robotics'], { fragment: course.fragment });
  }
}