<div class="carousel">
  <div class="images-wrapper">
    <div class="image-wrapper" *ngFor="let img of images; let i = index" [style.transform]="'translateX(' + (currentIndex * -100) + '%)'">
      <img [src]="img" alt="">
    </div>
  </div>
  
  <button class="btn prev" (click)="previousImg()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
  </button>
  
  <button class="btn next" (click)="nextImg()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
  </button>
</div> 

