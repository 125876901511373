
import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ImageProtectionService } from './image-protection.service';
@Directive({
  selector: '[appImageProtection]'
})
export class ImageProtectionDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private imageProtectionService: ImageProtectionService
  ) {}

  ngAfterViewInit(): void {
    const image = this.el.nativeElement;
    if (image.tagName === 'IMG') {
      // Disable right-click on this image
      this.imageProtectionService.disableRightClick();

      // Add watermark overlay to this image
      
    }
  }

}
