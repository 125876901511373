<!-- <app-a-side-nav></app-a-side-nav>
<div class="main-content">

  <div class="dashboard-container">
    <div class="counts">
      <div class="count-container">
        <div class="count-name">
          <h3>Buy Kit Count</h3>
        </div>
        <div class="count-number">
          <p>{{ buyKitCount }}</p>
        </div>
      </div>
      <div class="count-container">
        <div class="count-name">
          <h3>Subscribers Count</h3>
        </div>
        <div class="count-number">
          <p>{{ subscribersCount }}</p>
        </div>
      </div>
      <div class="count-container">
        <div class="count-name">
          <h3>Contact Us Count</h3>
        </div>
        <div class="count-number">
          <p>{{ contactUsCount }}</p>
        </div>
      </div>
      <div class="count-container">
        <div class="count-name">
          <h3>Scholarship Count</h3>
        </div>
        <div class="count-number">
          <p>{{ scholarshipCount }}</p>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-a-header></app-a-header>
<div class="main-content">
  <app-a-dashboard></app-a-dashboard>
</div>



<br><br><br>