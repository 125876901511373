// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-ai-ml-project',
//   templateUrl: './ai-ml-project.component.html',
//   styleUrls: ['./ai-ml-project.component.scss']
// })
// export class AiMlProjectComponent {

// }

import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ai-ml-project',
  templateUrl: './ai-ml-project.component.html',
  styleUrls: ['./ai-ml-project.component.scss']
})
export class AiMlProjectComponent {

  constructor(private router: Router) { }
  courses = [
    {
      title: 'Age and Gender Detection with Python',
      imgSrc: 'assets/ai-ml projects/Age and Gender Detection with Python.jpg',
      technology: "OpenCV , Dlib , TensorFlow.",
      fragment: 'age-and-gender-detection'
    },
    {
      title: 'AI in Healthcare',
      imgSrc: 'assets/ai-ml projects/Amazon Alexa Reviews Sentiment Analysis.jpg',
      technology: "OpenCV , Dlib , TensorFlow.",
      showInfo: false,
      fragment: 'ai-in-healthcare'
    },
    
    {
      title: 'Autocorrect Keyboard with Python and Machine Learning',
      imgSrc: 'assets/ai-ml projects/Autocorrect Keyboard with Python and Machine Learning.jpg',
      technology: "NLTK/spaCy , TensorFlow , Kivy.",
      fragment: 'autocorrect-keyboard-with-python'
    },
    {
      title: 'Automatic License Number Plate Recognition System',
      imgSrc: 'assets/ai-ml projects/Automatic License Number Plate Recognition System.jpg',
      technology: " OpenCV, Tesseract OCR, TensorFlow/Keras.",
      fragment: 'automatic-license-number-plate'
    },
    {
      title: 'Automatic Time Series Forecasting',
      imgSrc: 'assets/ai-ml projects/Automatic Time Series Forecasting.jpg',
      technology: "Pandas, Prophet, scikit-learn",
      fragment: 'automatic-time-series-forecasting'
    },
    {
      title: 'Crop Health Monitoring using AI and IoT',
      imgSrc: '/assets/robotics projects/Crop Health Monitoring using AI and IoT.jpeg',
      technology: "TensorFlow, Arduino, AWS IoT Core.",
      fragment: 'crop-health-monitoring'
    },
    {
      title: 'Real-time Soil Moisture Monitoring',
      imgSrc: '/assets/robotics projects/Real-time Soil Moisture Monitoring.jpeg',
      technology: "Arduino, Soil Moisture Sensor, Node.js / Flask, React.js / Angular",
      fragment: 'real-time-soil-moisture'
    },
    {
      title: 'Barbie with Brains Project',
      imgSrc: 'assets/ai-ml projects/Barbie with Brains Project.jpg',
      technology: "Raspberry Pi / Arduino, TensorFlow Lite, Google Cloud/AWS IoT.",
      fragment: 'barbie-with-brains-project'
    },
    {
      title: 'Build a Collaborative Filtering Recommender System in Python',
      imgSrc: 'assets/ai-ml projects/Build a Collaborative Filtering Recommender System in Python.jpg',
      technology: "Pandas, Surprise, scikit-learn.",
      fragment: 'build-collaborative-filterning'
    },
    {
      title: 'Similar Images Finder ML Project',
      imgSrc: 'assets/ai-ml projects/Build a Similar Images Finder ML Project.jpg',
      technology: "OpenCV, TensorFlow/Keras, scikit-learn.",
      fragment: 'similar-images-finder'
    },
    
  ];


  toggleInfo(course: any) {
    course.showInfo = !course.showInfo;
  }




  showPopup = false;
  name = '';
  message = '';


  showPopupBox() {
    this.showPopup = true;
    setTimeout(() => {
      const popupElement = document.querySelector('.popup');
      if (popupElement) {
        popupElement.classList.add('show');
      }
    }, 15);
  }

  hidePopup() {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.remove('show');
    }
    setTimeout(() => {
      this.showPopup = false;
    }, 500);
  }




  navigateToFragment(course: any) {
    this.router.navigate(['/ai-ml'], { fragment: course.fragment });
  }
}