
<app-main-header></app-main-header>
<br>
<br>
<br>
<div id="project-container">
  <H2 id="pro-hed"> Our Project's </H2>
  <br>
  <br>
  <br>
  <div id="Project-odd">
    <div id="pro-odd-cont">
      <div id="project-odd-tit">
        <h3>Heart Disease Prediction</h3>
        <p>Our Heart Disease Prediction project utilizes advanced machine learning algorithms to predict the likelihood
          of heart disease in individuals based on various health parameters. By analyzing factors such as age, gender,
          blood pressure, cholesterol levels, and more, our predictive model assists healthcare professionals in early
          detection and proactive management of cardiovascular risks. This project aims to improve patient outcomes and
          promote heart health awareness with a user-friendly interface and accurate predictions. Join us in our mission
          to combat heart disease and save lives through innovative technology solutions.</p>
      </div>
      <div id="project-odd-img">
        <img src="../../assets/image/heart rate.jpg" alt="" width="300px" height="300px">
      </div>
    </div>
  </div>
  <br>
  <br>
  <div id="Project-even">
    <div id="pro-even-cont">
      <div id="project-even-img">
        <img src="../../assets/image/hand1.jpg" alt="" width="300px" height="300px">
      </div>
      <div id="project-even-tit">
        <h3>Voice Controlled ARM</h3>
        <p>Voice Controlled ARM is an innovative project that harnesses the power of voice recognition technology to
          control an articulated robotic arm (ARM). By integrating cutting-edge speech recognition algorithms with
          precise robotic control mechanisms, users can effortlessly command the ARM using natural voice commands.
          Whether picking and placing objects, performing intricate tasks, or navigating complex environments, Voice
          Controlled ARM offers a seamless and intuitive interface for hands-free operation. This project opens up a
          world of possibilities in automation, accessibility, and human- machine interaction, paving the way for future
          advancements in robotics and assistive technologies.</p>
      </div>

    </div>
  </div>
  <br>
  <br>
  <div id="Project-odd">
    <div id="pro-odd-cont">
      <div id="project-odd-tit">
        <h3>Face Recognition System</h3>
        <p>Our Face Recognition System utilizes advanced computer Horizon algorithms to accurately identify and
          authenticate individuals based on their facial features. With the capability to analyze unique facial
          characteristics such as shape, texture, and contours, this system offers reliable and efficient recognition in
          various environments and lighting conditions. From access control and surveillance to personalized user
          experiences, our Face Recognition System provides a versatile solution for enhancing security and streamlining
          processes. Join us in harnessing the power of facial recognition technology to create safer, more convenient,
          and smarter environments for everyone.</p>
      </div>
      <div id="project-odd-img">
        <img src="../../assets/image/face.png" alt="" width="300px" height="300px">
      </div>
    </div>
  </div>
  <br>
  <br>
  <div id="Project-even">
    <div id="pro-even-cont">
      <div id="project-even-img">
        <img src="../../assets/image/smart car.webp" alt="" width="300px" height="300px">
      </div>
      <div id="project-even-tit">
        <h3>AI-Based Smart Car</h3>
        <p>Our Al-Based Smart Car represents the future of autonomous vehicles, integrating cutting-edge technology to
          deliver a truly intelligent driving experience. Equipped with advanced computer Horizon capabilities,
          including a high-resolution Hasky lens, this smart car can navigate dynamically by following black lines,
          detecting objects, and recognizing colors in its environment. By leveraging artificial intelligence
          algorithms, it adapts in real-time to changing road conditions, ensuring safe and efficient travel. Whether
          it's navigating complex routes or avoiding obstacles, our Al-based Smart Car is at the forefront of
          innovation, promising a seamless and intelligent driving experience for the future.</p>
      </div>

    </div>
  </div>
  <br>
  <br>
</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>
