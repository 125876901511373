<app-a-header></app-a-header>
<div>
    <h2>Student Course Information</h2>
</div>
<table class="table table-bordered">
    <thead class="thead-light">
        <tr>
            <th scope="col">Sr.No</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Standard</th>
            <th scope="col">Subject</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let student of students">
            <td>{{student.applicantId}}</td>
            <td>{{student.name}}</td>
            <td>{{student.email}}</td>
            <td>{{student.phone}}</td>
            <td>{{student.standard}}</td>
            <td>{{student.subject}}</td>
        </tr>
        
    </tbody>
</table>

