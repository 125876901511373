import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-academic-aligned-steam-course',
  templateUrl: './academic-aligned-steam-course.component.html',
  styleUrls: ['./academic-aligned-steam-course.component.scss']
})
export class AcademicAlignedSTEAMCourseComponent {
  constructor(private router: Router, private dataService: DataService) { }
  amount:number=1200;
  applyNow() {
    this.dataService.setAmount(this.amount);
    this.router.navigate(['/stramform'], { state: { amount:this.amount } });
    
  }
}
