<section>
  <div class="login-box">
    <img src="/assets/img/Logo-01.png" alt="Company Logo" class="logo">
    <h2>VS Tech Admin Login</h2>
    <form (ngSubmit)="onLogin()">
      <label for="email">Email:</label>
      <input type="email" id="email" [(ngModel)]="email" name="email" required>
      <label for="password">Password:</label>
      <input type="password" id="password" [(ngModel)]="password" name="password" required>
      <button type="submit">Login</button>
    </form>
  </div>
</section>
