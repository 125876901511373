// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-it-project',
//   templateUrl: './it-project.component.html',
//   styleUrls: ['./it-project.component.scss']
// })
// export class ItProjectComponent {

// }
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-it-project',
  templateUrl: './it-project.component.html',
  styleUrls: ['./it-project.component.scss']
})
export class ItProjectComponent {


  constructor(private router: Router) { }
  courses = [
    {
      title: 'School Management System (SMS)',
      imgSrc: 'assets/it projects/School Management System (SMS).jpg',
      technology: "React, Nodejs.",
      fragment: 'school-management-system-sms'
    },
    {
      title: 'Attendance Management System',
      imgSrc: 'assets/it projects/Attendance Management System.jpg',
      technology: "MEAN",
      fragment: 'attendance-management-system'


    },
    {
      title: 'Learning Management System (LMS)',
      imgSrc: 'assets/it projects/Learning Management System (LMS).jpg',
      technology: "MEAN",
      fragment: 'learning-management-system'
    },
    {
      title: 'Library Management System',
      imgSrc: 'assets/it projects/Library Management System.jpg',
      technology: "MEAN",
      fragment: 'library-management-system'
    },
    {
      title: 'Exam Management System',
      imgSrc: 'assets/it projects/Exam Management System.jpg',
      technology: "MEAN",
      fragment: 'exam-management-system'
    },
    {
      title: 'Online Admission System',
      imgSrc: 'assets/it projects/Online Admission System.jpg',
      technology: "MEAN",
      fragment: 'online-admission-system'
    },
    {
      title: 'Virtual Classroom System',
      imgSrc: 'assets/it projects/Virtual Classroom System.jpg',
      technology: "MEAN",
      fragment: 'virtual-classroom-system'
    },
    {
      title: 'Parent-Teacher Communication Portal',
      imgSrc: 'assets/it projects/Parent-Teacher Communication Portal.jpg',
      technology: "MEAN",
      fragment: 'parent-teacher-communication-portal'
    },
    {
      title: 'Transport Management System',
      imgSrc: 'assets/it projects/Transport Management System.jpg',
      technology: "MEAN",
      fragment: 'transport-management-system'
    },
    {
      title: 'Hostel Management System',
      imgSrc: 'assets/it projects/Hostel Management System.jpg',
      technology: "MEAN",
      fragment: 'hostel-management-system'
    },
    {
      title: 'Alumni Management System',
      imgSrc: 'assets/it projects/Alumni Management System.jpg',
      technology: "MEAN",
      fragment: 'alumni-management-system'
    },
    {
      title: 'E-Library System',
      imgSrc: 'assets/it projects/E-Library System.jpg',
      technology: "MEAN",
      fragment: 'e-library-system'
    },
  ];


  toggleInfo(course: any) {
    course.showInfo = !course.showInfo;
  }

  // openVideo(course: any) {
  //   this.videoModal.course = course;
  //   this.videoModal.openModal();
  // }
  // toggleInfo(course: any) {
  //   course.showInfo = !course.showInfo; // Toggle show/hide for the info popup
  // }



  showPopup = false;
  name = '';
  message = '';


  showPopupBox() {
    this.showPopup = true;
    setTimeout(() => {
      const popupElement = document.querySelector('.popup');
      if (popupElement) {
        popupElement.classList.add('show');
      }
    }, 15);
  }

  hidePopup() {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.remove('show');
    }
    setTimeout(() => {
      this.showPopup = false;
    }, 500);
  }


  navigateToFragment(course: any) {
    this.router.navigate(['/it'], { fragment: course.fragment });
  }

}