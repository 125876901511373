<!-- <header>
  <h1>Admin Panel</h1>
  <div *ngIf="isLoggedIn" class="dropdown">
    <div class="dropdown">
      <button class="dropbtn">Profile</button>
      <div class="dropdown-content">
        <a href="#" (click)="onLogout()">Logout</a>
      </div>
    </div> -->
    <header>
      <button (click)="toggleSidebar()" class="menu-btn"><i class="fas fa-bars"></i></button>
      <img id="vs-img" src="../../assets/image/vs tech logo.png" alt="VS Tech Logo">
        <h1>Admin Panel</h1>
        <div *ngIf="isLoggedIn" class="dropdown"> 
        <div class="dropdown">
          <span>Admin</span>
          <button  class="dropbtn">
            
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z"/>
            </svg>
          </button>
          <div class="dropdown-content">
            <a href="admin" (click)="onLogout()">Logout</a>
          </div>
        </div>
        </div>
    </header>
    
    <nav [ngClass]="{'sidebar': true, 'sidebar-open': isSidebarOpen}">
      <ul>
        <li><a href="/a-dashboard">Dashboard</a></li>
        <li><a href="/admin/contact">Contact Us</a></li>
        <li><a href="/admin/kit">Buy Kit</a></li>
        <li><a href="/admin/scholarship">Scholarship</a></li>
        <li><a href="/admin/student">Courses</a></li>
        <li><a href="admin/subscribe">Subscribe</a></li>
        <li><a href="admin/job-application">Job Applicants</a></li>
        <li><a href="/admin/job-details">Job Details</a></li>
        <li><a href="/admin/careerguidance">Career Guidance</a></li>
        <li><a href="/admin/steamregistration">STEAM Registration </a></li>
      </ul>
    </nav>