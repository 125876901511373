<app-main-header></app-main-header>
<div class="shipping-policy">
  <h1>Shipping and Delivery</h1>

  <h2>1. Order Processing</h2>
  <ul>
    <li>
      <p>Orders for educational kits and materials are processed within 2-3 business days from the date of order
        placement. </p>
        
    </li>
    <li>
        <p>Orders placed on weekends or public holidays will be processed on the next business day.</p>
    </li>
  </ul>
  <h2>2. Shipping Locations</h2>
  <ul>
    <li>
  <p>We currently ship to addresses within India. Please ensure that your shipping address is accurate and complete to
    avoid any delays.</p>
</li>
</ul>
  <h2>3. Shipping Methods and Charges</h2>
  <ul>
    <li>
  <p>We offer standard shipping for all orders. Shipping charges are calculated based on the delivery location and the
    weight of the package. The total shipping cost will be displayed at checkout before you finalize your purchase.</p>
</li>
</ul>
  <h2>4. Delivery Time</h2>
  <ul>
    <li>
  <p>Standard delivery times are between 5-7 business days from the date of shipment. </p>
</li>
<li>
    <p>Please note that delivery times
        may vary based on the delivery location and any unforeseen circumstances such as weather conditions, strikes, or
        other disruptions.</p>
</li>
</ul>
  <h2>5. Tracking Your Order</h2>
  <ul>
    <li>
  <p>Once your order has been shipped, you will receive an email with the tracking information. You can use this
    information to track the status of your delivery on the carrier’s website.</p>
</li>
</ul>
  <h2>6. Delivery Issues</h2>
  <ul>
    <li>
  <p>If you experience any issues with your delivery, such as delays, incorrect items, or damaged packages, please
    contact our support team immediately at <a href="mailto:support@vstechhorizon.com">support@vstechhorizon.com</a> or
    call us at +91 9325360357. We will work to resolve the issue as quickly as possible.</p>
</li>
</ul>
  <h2>7. International Shipping</h2>
  <ul>
    <li>
  <p>At this time, we do not offer international shipping. We only ship within India.</p>
</li>
</ul>
  <h2>8. Undeliverable Packages</h2>
  <ul>
    <li>
  <p>If a package is returned to us as undeliverable due to incorrect address information provided by the customer, we
    will notify you and arrange for re-shipment. Additional shipping charges may apply.</p>
</li>
</ul>
  <h2>9. Changes to the Shipping Policy</h2>
  <ul>
    <li>
  <p>VS Tech Horizon reserves the right to modify this Shipping and Delivery Policy at any time. Any changes will be
    reflected on this page, and the revised policy will be effective immediately upon posting.</p>
</li>
</ul>
  <h2>Contact Us</h2>
  <ul>
    <li>
  <p>If you have any questions or concerns about our Shipping and Delivery Policy, please contact us at:</p>
  <p>Email: <a href="mailto:support@vstechhorizon.com">support@vstechhorizon.com</a></p>
  <p>Phone: +91 9325360357</p>
</li>
</ul>
  <p>By placing an order with VS Tech Horizon, you agree to the terms outlined in this Shipping and Delivery Policy.
    Thank you for choosing VS Tech Horizon for your educational needs!</p>
</div>
<app-footer></app-footer>