<!-- <div class="container">
    <header>
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the services provided by VS Tech Horizon Private Limited ("Company", "We", "Us", or "Our") and informs you about your privacy rights and how the law protects you.</p>
        <p>By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
    </header>

    <section id="interpretation">
        <h2>1. Interpretation and Definitions</h2>
        <p><strong>Interpretation:</strong> The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
        <p><strong>Definitions:</strong></p>
        <ul>
            <li><strong>Account:</strong> means a unique account created for you to access our Service or parts of our Service.</li>
            <li><strong>Cookies:</strong> are small files that are placed on your computer, mobile device, or any other device by a website, containing the details of your browsing history on that website among its many uses.</li>
            <li><strong>Personal Data:</strong> any information that relates to an identified or identifiable individual.</li>
            <li>... (continue defining other terms as needed)</li>
        </ul>
    </section>

    <section id="collecting">
        <h2>2. Collecting and Using Your Personal Data</h2>
        <p><strong>Types of Data Collected:</strong></p>
        <p>While using Our Service, We may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>College/Institution Details and Location</li>
            <li>User-uploaded photos and IDs</li>
            <li>Demographic information such as postcode, preferences, and interests</li>
            <li>Any other personal information that you give us in connection while using our services</li>
        </ul>
        <p><strong>Usage Data:</strong></p>
        <p>Usage Data is collected automatically when using the Service. It may include information such as your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
    </section>

    <section id="cookies">
        <h2>3. Tracking Technologies and Cookies</h2>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies we use may include:</p>
        <ul>
            <li><strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on your Device. You can instruct your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted your browser setting so that it will refuse cookies, our Service may use Cookies.</li>
            <li><strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
            <li>... (add details on other tracking technologies used)</li>
        </ul>
    </section>

    <section id="use">
        <h2>4. Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li>To provide and maintain our Service, including monitoring the usage of our Service.</li>
            <li>To manage Your Account: to manage your registration as a user of the Service. The Personal Data you provide can give you access to different functionalities of the Service that are available to you as a registered user.</li>
            <li>For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or of any other contract with us through the Service.</li>
            <li>... (continue listing other purposes)</li>
        </ul>
    </section>

   
    <section id="changes">
        <h2>10. Changes to this Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    </section>

    <footer>
        <p>If you have any questions about this Privacy Policy, you can contact us:</p>
        <ul>
            <li>By email: <a href="mailto:privacy@vstechhorizon.com">privacy@vstechhorizon.com</a></li>
        </ul>
    </footer>
</div> -->

<app-main-header></app-main-header>

<div class="container">
    <header>
        <h1>Privacy Policy Information</h1>
       <br>
        <p><strong>Last updated: July 13, 2024</strong></p>
        <p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the services provided by VS Tech Horizon Private Limited ("Company", "We", "Us", or "Our") and informs you about your privacy rights and how the law protects you.</p>
        <p>By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
    </header>

    <section id="interpretation">
        <h2>1. Interpretation and Definitions</h2>
        <p><strong>Interpretation:</strong> The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
        <p><strong>Definitions</strong></p>
        <p><strong>For the purposes of this Privacy Policy:</strong></p>
        <ul>
            <li><b>Account:</b> A unique account created for you to access our Service or parts of our Service.</li>
            <li><b>Company:</b> VS Tech Horizon Private Limited, located at Plot No.13 Gat No.574 Sam Fransisco Park, Matosh, Haveli, Kesnand Pune-412207.</li>
            <li><b>Cookies:</b> Small files placed on your computer, mobile device, or any other device by a website, containing details of your browsing history on that website among its many uses.</li>
            <li><strong>Country:</strong> India.</li>
            <li><strong>Device:</strong> Any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>
            <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
            <li><strong>Service:</strong> The educational services and platforms provided by the Company, including our website and mobile application.</li>
            <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company.</li>
            <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li><strong>Website:</strong> VS Tech Horizon, accessible from <a href="https://vstechhorizon.com/">https://vstechhorizon.com/</a>.</li>
            <li><strong>You:</strong> The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>
    </section>

    <section id="collecting">
        <h2>2. Collecting and Using Your Personal Data</h2>
        <p><strong>Types of Data Collected:</strong></p>
       <p><strong>Personal Data:</strong></p>
        <p>While using Our Service, We may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>College/Institution Details and Location</li>
            <li>User-uploaded photos and IDs</li>
            <li>Demographic information such as postcode, preferences, and interests</li>
            <li>Any other personal information that you give us in connection while using our services</li>
        </ul>
        <p><strong>Usage Data:</strong></p>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>It may include information such as your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
        <p>When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.</p>
        <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device.</p>
    </section>

    <section id="cookies">
        <h2>3. Tracking Technologies and Cookies</h2>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies we use may include:</p>
        <ul>
            <li><strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on your Device. You can instruct your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted your browser setting so that it will refuse cookies, our Service may use Cookies.</li>
            <li><strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
        </ul>
    </section>
    <!-- <strong></strong>  -->
    <section id="use">
        <h2>4. Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li>To provide and maintain our Service, including monitoring the usage of our Service.</li>
            <li><strong>To manage Your Account: </strong> to manage your registration as a user of the Service. The Personal Data you provide can give you access to different functionalities of the Service that are available to you as a registered user.</li>
            <li><strong>For the performance of a contract:</strong> The development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or of any other contract with us through the Service.</li>
            <li><strong>To contact You:</strong> To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
            <li>To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</li>
            <li><strong>To manage Your requests:</strong> To attend and manage your requests to us.</li>
            <li><strong>For business transfers:</strong> We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by us about our Service users is among the assets transferred.</li>
            <li><strong>For other purposes:</strong> We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and your experience.</li>
        </ul>
    </section>

    <section id="sharing">
        <h2>5. Sharing of Personal Information</h2>
        <p>We may share your personal information in the following situations:</p>
        <ul>
            <li><strong>With Service Providers:</strong> We may share your personal information with Service Providers to monitor and analyze the use of our Service, and to contact you.</li>
            <li><strong>For business transfers: </strong>We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            <li><strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
            <li><strong>With business partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
            <li><strong>With other users:</strong> When you share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
            <li><strong>With your consent:</strong> We may disclose your personal information for any other purpose with your consent.</li>
        </ul>
    </section>

    <section id="retention">
        <h2>6. Retention of Your Personal Data</h2>
        <p>The Company will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
    </section>

    <section id="security">
        <h2>7. Security of Your Personal Data</h2>
        <p>The security of Your Personal Data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
    </section>

    <section id="children">
        <h2>8. Children's Privacy</h2>
        <p>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
    </section>

    <section id="links">
        <h2>9. Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated by Us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
    </section>

    <section id="changes">
        <h2>10. Changes to this Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    </section>

    <footer>
        <p>If you have any questions about this Privacy Policy, you can contact us:</p>
        <ul>
            <li>By email: <a href="mailto:vstechhorizon@gmail.com">vstechhorizon@gmail.com</a></li>
        </ul>
    </footer>
</div>

<app-footer></app-footer>