

<section class="pt-5 pb-5">
  <br>
  <div class="container">
    <div class="row justify-content-center">
    </div>
    <div class="row">
      <div class="col-12 d-flex align-items-center">
        <div id="carouselExampleIndicators2" class="carousel slide carousel-fade" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md-4 mb-3 cards">
                  <div class="card">
                    <!-- <img class="img-fluid" alt="100%x280" src="../../assets/img/scholarship1.avif"> -->
                    <a href="/scholarship"><img  class="img-fluid" alt="100%x280" src="../../assets/img/scholarship1.avif"></a>
                    <div class="card-body">
                      <h4 class="card-title text-center"><b>Scholarship</b></h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <!-- <img class="img-fluid" alt="100%x280" src="../../assets/img/gaudance1.jpg"> -->
                    <a (click)="scrollToSection('career-guidance')"><img class="img-fluid" alt="100%x280" src="../../assets/img/gaudance1.jpg"></a>
                    <div class="card-body">
                      <h4 class="card-title text-center"><b>Career Guidance</b></h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="img-fluid" alt="100%x280" src="../../assets/img/workshop (2).jpg">
                    <div class="card-body">
                      <h4 class="card-title text-center"><b>Workshops & Seminars</b></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <!-- <img class="img-fluid" alt="100%x280" src="../../assets/img/steam_education_01.webp"> -->
                    <!-- <a [routerLink]="'/steam'"><img class="img-fluid" alt="100%x280" src="../../assets/img/steam-education-why-integrate-arts-into-stem[1].jpg"></a> -->
                    <a [routerLink]="'/steam'"><img class="img-fluid" alt="100%x280" src="../../assets/img/cartoon-ai-robot-scene.jpg"></a>
                    <div class="card-body">
                      <h4 class="card-title text-center"><b>STEAM</b></h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <!-- <img class="img-fluid" alt="100%x280" src="../../assets/img/live1.avif"> -->
                    <a (click)="scrollToSection('course')"><img class="img-fluid" alt="100%x280" src="../../assets/img/live1.avif"></a>
                    <div class="card-body">
                      <h4 class="card-title text-center"><b>Live Courses</b></h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card">
                    <img class="img-fluid" alt="100%x280" src="../../assets/img/placement1.jpg">
                    <div class="card-body">
                      <h4 class="card-title text-center"><b>Placement preparation</b></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Previous and Next buttons -->
          <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
            <button class="scroll-btn prev" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                <path fill="none" d="M0 0h24v24H0z" />
              </svg>
            </button>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
            <button class="scroll-btn next" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                <path fill="none" d="M0 0h24v24H0z" />
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
