import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // constructor(private router: Router) {}
  // login(email: string, password: string) {
  //   if (email === 'vstechhorizon@gmail.com' && password === 'VStechhorizonadmin@ajays8082') {
  //     const sessionToken = new Date().getTime().toString(); // Using timestamp as a session token
  //     localStorage.setItem('isLoggedIn', 'true');
  //     localStorage.setItem('sessionToken', sessionToken);
  //     this.router.navigate(['/admin-dashboard']);
  //   } else {
  //     alert('Invalid credentials');
  //   }
  // }

  constructor(private http: HttpClient, private router: Router) {}

  login(username: string, password: string) {
    console.log("inside auth service");

    this.http.post('https://vstechhorizon.com:3006/login', { username, password })
      .subscribe((response: any) => {
        console.log(response);
        localStorage.setItem('token', response.token);
      const sessionToken = new Date().getTime().toString(); // Using timestamp as a session token
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('sessionToken', sessionToken);
        this.router.navigate(['/admin-dashboard']);
      }, (error) => {
        alert('Invalid credentials');
      });
  }

  logout() {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  
  isAuthenticated(): boolean {
    return localStorage.getItem('isLoggedIn') === 'true';
  }
}
