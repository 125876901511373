<!-- <app-main-header></app-main-header>
<div class="main">
  <div class="container">
    <form [formGroup]="steamForm" (ngSubmit)="onSubmit()">
      <h1><b>STEAM Registration Form</b></h1>
      <div class="form-group">
        <label for="name">Full name </label>
        <input id="name" formControlName="name" class="form-control" />
        <div *ngIf="steamForm.get('name')!.invalid && (steamForm.get('name')!.dirty || steamForm.get('name')!.touched)">
          <div *ngIf="steamForm.get('name')!.errors!['required']" class="error-message">Name is required.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="sname">School Name</label>
        <input id="sname" formControlName="sname" class="form-control" />
        <div
          *ngIf="steamForm.get('sname')!.invalid && (steamForm.get('sname')!.dirty || steamForm.get('sname')!.touched)">
          <div *ngIf="steamForm.get('sname')!.errors!['required']" class="error-message">School Name is required.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="std">Standard</label>
        <select id="std" formControlName="std" class="form-control">
          <option value="">Select Standard</option>
          <option value="5th">5th</option>
          <option value="6th">6th</option>
          <option value="7th">7th</option>
          <option value="8th">8th</option>
          <option value="9th">9th</option>
          <option value="10th">10th</option>
        </select>
        <div *ngIf="steamForm.get('std')!.invalid && (steamForm.get('std')!.dirty || steamForm.get('std')!.touched)">
          <div *ngIf="steamForm.get('std')!.errors!['required']" class="error-message">Standard is required.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="number">Mobile Number</label>
        <input id="number" formControlName="number" class="form-control" />
        <div
          *ngIf="steamForm.get('number')!.invalid && (steamForm.get('number')!.dirty || steamForm.get('number')!.touched)">
          <div *ngIf="steamForm.get('number')!.errors!['required']" class="error-message">Mobile Number is required.
          </div>
          <div *ngIf="steamForm.get('number')!.errors!['pattern']" class="error-message">Mobile Number must be 10
            digits.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email <span>*</span></label>
        <input id="email" type="email" formControlName="email" class="form-control" />
        <div
          *ngIf="steamForm.get('email')!.invalid && (steamForm.get('email')!.dirty || steamForm.get('email')!.touched)">
          <div *ngIf="steamForm.get('email')!.errors!['required']" class="error-message">Email is required.</div>
          <div *ngIf="steamForm.get('email')!.errors!['email']" class="error-message">Enter a valid email.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="dateOfBirth">Date of Birth <span>*</span></label>
        <input id="dateOfBirth" type="date" formControlName="dateOfBirth" class="form-control" />
        <div
          *ngIf="steamForm.get('dateOfBirth')!.invalid && (steamForm.get('dateOfBirth')!.dirty || steamForm.get('dateOfBirth')!.touched)">
          <div *ngIf="steamForm.get('dateOfBirth')!.errors!['required']" class="error-message">Date of Birth is
            required.</div>
        </div>
      </div>

      <div class="button">
        <button type="submit" class="btn btn-primary">Register</button>
      </div>
    </form>
  </div>
</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer> -->
<app-main-header></app-main-header>
<div class="main">
  <div class="container">
    <form [formGroup]="steamForm" (ngSubmit)="onSubmit()">
      

      <!-- Registration Section -->
      <div *ngIf="!showPaymentSection">
        <h1><b>STEAM Registration Form</b></h1>
        <div class="form-group">
          <label for="name">Full name <span>*</span></label>
          <input id="name" formControlName="name" class="form-control" />
          <div *ngIf="steamForm.get('name')!.invalid && (steamForm.get('name')!.dirty || steamForm.get('name')!.touched)">
            <div *ngIf="steamForm.get('name')!.errors!['required']" class="error-message">Name is required.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="sname">School Name <span>*</span></label>
          <input id="sname" formControlName="sname" class="form-control" />
          <div *ngIf="steamForm.get('sname')!.invalid && (steamForm.get('sname')!.dirty || steamForm.get('sname')!.touched)">
            <div *ngIf="steamForm.get('sname')!.errors!['required']" class="error-message">School Name is required.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="std">Standard <span>*</span></label>
          <select id="std" formControlName="std" class="form-control">
            <option value="">Select Standard</option>
            <option value="5th">1th</option>
            <option value="5th">2th</option>
            <option value="5th">3th</option>
            <option value="5th">4th</option>
            <option value="5th">5th</option>
            <option value="6th">6th</option>
            <option value="7th">7th</option>
            <option value="8th">8th</option>
            <option value="9th">9th</option>
            <option value="10th">10th</option>
          </select>
          <div *ngIf="steamForm.get('std')!.invalid && (steamForm.get('std')!.dirty || steamForm.get('std')!.touched)">
            <div *ngIf="steamForm.get('std')!.errors!['required']" class="error-message">Standard is required.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="number">Mobile Number <span>*</span></label>
          <input id="number" formControlName="number" class="form-control" />
          <div *ngIf="steamForm.get('number')!.invalid && (steamForm.get('number')!.dirty || steamForm.get('number')!.touched)">
            <div *ngIf="steamForm.get('number')!.errors!['required']" class="error-message">Mobile Number is required.</div>
            <div *ngIf="steamForm.get('number')!.errors!['pattern']" class="error-message">Mobile Number must be 10 digits.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email <span>*</span></label>
          <input id="email" type="email" formControlName="email" class="form-control" />
          <div *ngIf="steamForm.get('email')!.invalid && (steamForm.get('email')!.dirty || steamForm.get('email')!.touched)">
            <div *ngIf="steamForm.get('email')!.errors!['required']" class="error-message">Email is required.</div>
            <div *ngIf="steamForm.get('email')!.errors!['email']" class="error-message">Enter a valid email.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="dateOfBirth">Date of Birth <span>*</span></label>
          <input id="dateOfBirth" type="date" formControlName="dateOfBirth" class="form-control" />
          <div *ngIf="steamForm.get('dateOfBirth')!.invalid && (steamForm.get('dateOfBirth')!.dirty || steamForm.get('dateOfBirth')!.touched)">
            <div *ngIf="steamForm.get('dateOfBirth')!.errors!['required']" class="error-message">Date of Birth is required.</div>
          </div>
        </div>

        <div class="button">
          <button type="button" class="btn btn-primary" (click)="goToPaymentSection()">Next</button>
        </div>
      </div>

      <!-- Payment Section -->
      <div *ngIf="showPaymentSection">
        <h1>Payment Section</h1>
        <div class="button">
          <button type="button" class="btn btn-primary" (click)="pay()">Pay Now</button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>
