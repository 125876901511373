import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
@Component({
  selector: 'app-three-month-steam-steam-course',
  templateUrl: './three-month-steam-steam-course.component.html',
  styleUrls: ['./three-month-steam-steam-course.component.scss']
})
export class ThreeMonthSteamSteamCourseComponent {
  constructor(private router: Router, private dataService: DataService) { }
  amount:number=330;
  applyNow() {
    this.dataService.setAmount(this.amount);
    this.router.navigate(['/stramform'], { state: { amount:this.amount } });
    
  }
}
