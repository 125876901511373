
import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-carouselboot',
  templateUrl: './carouselboot.component.html',
  styleUrls: ['./carouselboot.component.css']
})
export class CarouselbootComponent {
  constructor(private router: Router) { }

  scrollToSection(section: string) {
    this.router.navigate([''], { fragment: section });
  }


}
