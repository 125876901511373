<app-main-header></app-main-header>
<div class="course-container">
  <div class="course-header">
    🌟 Enroll in the Academic-Aligned STEAM Course! 🌟
  </div>
  <br>
  <div class="course-body">
    <p class="course-description">
      Join our Academic-Aligned STEAM course, tailored to integrate seamlessly with school curriculums. Designed to
      enhance your academic journey, this course explores Science, Technology, Engineering, Arts, and Mathematics
      (STEAM) through practical projects and interdisciplinary learning.
    </p>
    <br>
    <div class="course-details">
      <div class="course-detail-item"><strong>Duration:</strong> Customizable to align with school terms
      </div>
      <div class="course-detail-item"><strong>Schedule:</strong> Flexible scheduling to complement academic calendars
      </div>
      <div class="course-detail-item"><strong>Cost:</strong> ₹1200 registration fee</div>
      <div class="course-detail-item"><strong>Benefits:</strong> Strengthened academic performance, practical skills,
        and enhanced problem-solving abilities</div>
    </div>
    <p class="course-footer">
      Don’t miss this opportunity to boost your academic achievements and explore the exciting world of STEAM. Register
      now and elevate your learning experience!</p>
  </div>
  <div class="course-footer">
    <button class="register-button"   (click)="applyNow()" >Register Now</button>
  </div>
</div>
<app-footer></app-footer>
