<!-- <app-header></app-header> -->
<app-main-header></app-main-header>
<div class="form-container">
  
  <form [formGroup]="careerForm" novalidate>
    <div *ngIf="!showPaymentSection">
      <h1>Career Guidance Application</h1>
      <div class="form-group">
        <label for="fullName">Full Name <span>*</span></label>
        <input type="text" id="fullName" formControlName="fullName" placeholder="Full Name">
        <div *ngIf="careerForm.get('fullName')?.invalid && (careerForm.get('fullName')?.touched || careerForm.get('fullName')?.dirty)" class="error">
          Full Name is required.
        </div>
      </div>

      <div class="form-group">
        <label for="dob">Date of Birth (DOB) <span>*</span></label>
        <input type="date" id="dob" formControlName="dob" placeholder="Date of Birth (DOB)">
        <div *ngIf="careerForm.get('dob')?.invalid && (careerForm.get('dob')?.touched || careerForm.get('dob')?.dirty)" class="error">
          Date of Birth is required.
        </div>
      </div>

      <div class="form-group">
        <label for="mobileNumber">Mobile Number <span>*</span></label>
        <input type="tel" id="mobileNumber" formControlName="mobileNumber" placeholder="Mobile Number">
        <div *ngIf="careerForm.get('mobileNumber')?.invalid && (careerForm.get('mobileNumber')?.touched || careerForm.get('mobileNumber')?.dirty)" class="error">
          <div *ngIf="careerForm.get('mobileNumber')?.errors?.['required']">Mobile Number is required.</div>
          <div *ngIf="careerForm.get('mobileNumber')?.errors?.['pattern']">Mobile Number must be 10 digits.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email <span>*</span></label>
        <input type="email" id="email" formControlName="email" placeholder="Email">
        <div *ngIf="careerForm.get('email')?.invalid && (careerForm.get('email')?.touched || careerForm.get('email')?.dirty)" class="error">
          <div *ngIf="careerForm.get('email')?.errors?.['required']">Email is required.</div>
          <div *ngIf="careerForm.get('email')?.errors?.['email']">Invalid email format.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="gender">Gender <span>*</span></label>
        <select id="gender" formControlName="gender">
          <option value="" disabled selected>Select your gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
        <div *ngIf="careerForm.get('gender')?.invalid && (careerForm.get('gender')?.touched || careerForm.get('gender')?.dirty)" class="error">
          Gender is required.
        </div>
      </div>

      <div class="form-group">
        <label for="discussionPoint">Point you want to discuss</label>
        <textarea id="discussionPoint" formControlName="discussionPoint" placeholder="Point you want to discuss"></textarea>
        <div *ngIf="careerForm.get('discussionPoint')?.invalid && (careerForm.get('discussionPoint')?.touched || careerForm.get('discussionPoint')?.dirty)" class="error">
          Discussion point is required.
        </div>
      </div>

      <div class="form-group form-submit">
        
        <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="careerForm.invalid">Next</button>
      </div>
    </div>

    <div *ngIf="showPaymentSection">
      <h2>Payment Section</h2>
      <button type="button" class="btn btn-primary" (click)="pay()">Pay Now</button>
    </div>
  </form>
</div>
<app-footer></app-footer>