<!-- <p>career-guidance-sub works!</p>
<app-main-header></app-main-header>
<div id="career-guidance-sub">
  <h1>Career Guidance Plans</h1>
  <br>
  <div class="career-guidance-container">


    <div class="guidance-section">
      <img src="../../assets/image/images.jpg" alt="">
      <div class="guidance-section-content">
        <h2>One-Time Career Guidance Session</h2>

        <p>
          Unlock your potential with a personalized one-time career guidance session. This option is perfect for
          students and professionals seeking immediate advice on career choices, educational pathways, and job
          opportunities. 
        </p>
        <p class="sub-content">
          Our expert counselors will help you:
        </p>
        <ul>
          <li>Identify your strengths and interests</li>
          <li>Explore suitable career options</li>
          <li>Plan your educational journey</li>
          <li>Gain insights into industry trends and job markets</li>
        </ul>
        <p class="sub-content">Benefits:</p>
        <ul>
          <li>Personalized one-on-one advice tailored to your needs</li>
          <li>Clear action plan for your career goals</li>
          <li>Access to valuable resources and tools</li>
        </ul>
        <p>
          Fees: 49
        </p>
        <button class="apply-now-button"  [routerLink]="['/applycareerguidancefrom']" [queryParams]="{ amount: 49 }">Apply Now</button>
      </div>
    </div>

    <div class="guidance-section">
      <img src="../../assets/image/career-banner.1e199ce79dca.jpg" alt="">
      <div class="guidance-section-content">
        <h2>One-Month Career Guidance Program</h2>

        <p>
          Our one-month career guidance program offers ongoing support and deeper insights to help you navigate your career path 
        </p>
        <p class="sub-content">
          For a month, you will receive:

        </p>
        <ul>
          <li>Weekly one-on-one counseling sessions
          </li>
          <li>Continuous support and guidance
          </li>
          <li>Detailed career assessments and feedback
          </li>
          <li>Assistance with resume building and interview preparation
          </li>
        </ul>
        <p class="sub-content">Benefits:</p>
        <ul>
          <li>
            Comprehensive and continuous support
            </li>
          <li>In-depth career planning and development
          </li>
          <li>Enhanced job search strategies and skills</li>
        </ul>
        <p>
          Fees: 449
        </p>
        <button class="apply-now-button"  [routerLink]="['/applycareerguidancefrom']" [queryParams]="{ amount: 499 }">Apply Now</button>
      </div>
    </div>

    <div class="guidance-section">
      <img src="../../assets/image/Career-Counselling-in-India-via-online_1.jpg" alt="">
      <div class="guidance-section-content">
        <h2>One-Year Career Guidance Program
        </h2>

        <p>
          Invest in your future with our one-year membership career guidance program. This exclusive option provides you with regular access to career counseling and support throughout the year. 
        </p>
        <p class="sub-content">
          With this program, you will benefit from:

        </p>
        <ul>
          <li>One-on-one session per month, with the option to increase the number of sessions as needed
          </li>
          <li>Regular updates on industry trends and opportunities
          </li>
          <li>Long-term career planning and mentorship
          </li>
          <li>Support through all stages of your career
          </li>
        </ul>
        <p class="sub-content">Benefits:</p>
        <ul>
          <li>Ongoing access to expert advice and support
          </li>
          <li>Adaptability to changing career landscapes
          </li>
          <li>Continuous mentorship and professional growth</li>
        </ul>
        <p>
          Fees: 1111
        </p>
        <button class="apply-now-button"  [routerLink]="['/applycareerguidancefrom']" [queryParams]="{ amount: 1111 }">Apply Now</button>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer> -->
<!-- <p>career-guidance-sub works!</p> -->
<app-main-header></app-main-header>
<div id="career-guidance-sub">
  <h1>Career Guidance Plans</h1>
  <br>
  <div class="career-guidance-container">
    <div class="guidance-section">
      <img src="../../assets/image/images.jpg" alt="">
      <div class="guidance-section-content">
        <h2>One-Time Career Guidance Session</h2>

        <p>
          Unlock your potential with a personalized one-time career guidance session. This option is perfect for
          students and professionals seeking immediate advice on career choices, educational pathways, and job
          opportunities. 
        </p>
        <p class="sub-content">
          Our expert counselors will help you:
        </p>
        <ul>
          <li>Identify your strengths and interests</li>
          <li>Explore suitable career options</li>
          <li>Plan your educational journey</li>
          <li>Gain insights into industry trends and job markets</li>
        </ul>
        <p class="sub-content">Benefits:</p>
        <ul>
          <li>Personalized one-on-one advice tailored to your needs</li>
          <li>Clear action plan for your career goals</li>
          <li>Access to valuable resources and tools</li>
        </ul>
        <p>
          Fees: ₹49
        </p>
      </div>
      <button class="apply-now-button"  (click)="applyNow(49)">Apply Now</button>
    </div>

    <div class="guidance-section">
      <img src="../../assets/image/career-banner.1e199ce79dca.jpg" alt="">
      <div class="guidance-section-content">
        <h2>One-Month Career Guidance Program</h2>

        <p>
          Our one-month career guidance program offers ongoing support and deeper insights to help you navigate your career path 
        </p>
        <p class="sub-content">
          For a month, you will receive:

        </p>
        <ul>
          <li>Weekly one-on-one counseling sessions</li>
          <li>Continuous support and guidance</li>
          <li>Detailed career assessments and feedback</li>
          <li>Assistance with resume building and interview preparation</li>
        </ul>
        <p class="sub-content">Benefits:</p>
        <ul>
          <li>Comprehensive and continuous support</li>
          <li>In-depth career planning and development</li>
          <li>Enhanced job search strategies and skills</li>
        </ul>
        <p>
          Fees: ₹449
        </p>
      </div>
      <button class="apply-now-button" (click)="applyNow(499)">Apply Now</button>
    </div>

    <div class="guidance-section">
      <img src="../../assets/image/Career-Counselling-in-India-via-online_1.jpg" alt="">
      <div class="guidance-section-content">
        <h2>One-Year Career Guidance Program</h2>

        <p>
          Invest in your future with our one-year membership career guidance program. This exclusive option provides you with regular access to career counseling and support throughout the year. 
        </p>
        <p class="sub-content">
          With this program, you will benefit from:

        </p>
        <ul>
          <li>One-on-one session per month, with the option to increase the number of sessions as needed</li>
          <li>Regular updates on industry trends and opportunities</li>
          <li>Long-term career planning and mentorship</li>
          <li>Support through all stages of your career</li>
        </ul>
        <p class="sub-content">Benefits:</p>
        <ul>
          <li>Ongoing access to expert advice and support</li>
          <li>Adaptability to changing career landscapes</li>
          <li>Continuous mentorship and professional growth</li>
        </ul>
        <p>
          Fees: ₹1111
        </p>
      </div>
      <button class="apply-now-button" (click)="applyNow(1111)">Apply Now</button>
    </div>
  </div>

</div>

<app-footer></app-footer>
