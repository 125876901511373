<app-main-header></app-main-header>
<div class="container">
  <h1>Payment Details</h1>
  <div class="details">
    <!-- <h3>Bank Account details</h3>
    <div>
      Account Name: VS TECH HORIZON PRIVATE LIMITED
    </div>
    <div>
      Branch Name: State Bank of India, Wagholi
    </div>
    <div>
      Account No: 00000042992398554
    </div>
    <div>
      IFSC Code: SBIN0013258
    </div>
    <div>
      UPI Id: <span (click)="copyUPIId()" style="cursor: pointer;">7744831533@sbi</span>
    </div> -->
    <p class="fee">Registration fee: 999/-</p>
    <button class="btn btn-primary" (click)="payNow()">Pay amount</button>
  </div>

  <!-- <h3>Scan the QR code</h3>
  <p>vstechhorizon HR</p>
  <img src="../../assets/img/qrcode1.jpg" alt="QR Code">
  <app-paypal[amount]="amount" (paymentSuccess)="onPaymentSuccess($event)"></app-paypal>
  
  <div id="paypal">
   <app-paypal [amount]="amount" (paymentSuccess)="onPaymentSuccess($event)"></app-paypal>
  </div >
  
  <div>
    <label for="transactionId">Transaction ID:</label>
    <input type="text" id="transactionId" [(ngModel)]="transactionId" (input)="checkFormValidity()"
      class="form-control">
  </div>
  <div>
    <label for="screenshot">Upload Screenshot:</label>
    <input type="file" id="screenshot" (change)="onFileChange($event)" class="form-control">
  </div>
  <button  [disabled]="!formValid" (click)="submitPayment()">Submit</button> -->
</div>

<!-- Popup Dialog -->
<div id="popupDialog" class="popup-dialog" style="display: none;">
  <div class="popup-content">
    <h1>Scholarship Registration Received</h1>
    <p>Thank you for registering.</p>
    <p>We have received your payment details. Your registration will be confirmed once we verify your transaction ID.
    </p>
    <p>Our team is reviewing your payment. This process typically takes 2-3 business days. We will notify you via email
      once confirmed.</p>
    <p>Thank you for Registering.</p>
    <button class="btn-3 btn-primary" (click)="closePopup()">OK</button>
       
  </div>
</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>

