import { Component, OnInit } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-steam',
  templateUrl: './steam.component.html',
  styleUrls: ['./steam.component.scss']
})
export class STEAMComponent {

  STEAMRegistration: any[] = [];


  private baseUrl = 'https://vstechhorizon.com:3006';

  constructor(private router: Router, private http: HttpClient, private authService: AuthService) { }

  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.get<any[]>(`${this.baseUrl}/getsteamregister`,{ headers: headers }).subscribe(
      data => {
        this.STEAMRegistration = data;
        console.log(this.STEAMRegistration); // Log the data after assignment
      },
      error => console.error('Error fetching resumes:', error)
    );
  }

}


