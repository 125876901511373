import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-python',
  templateUrl: './python.component.html',
  styleUrls: ['./python.component.scss']
})
export class PythonComponent {
  showTopic1 = true;
  showTopic2 = false;
  showTopic3 = false;

  videoUrl: SafeResourceUrl | null = null;
  constructor(private sanitizer: DomSanitizer) {}

  toggleTopic(topic: number) {
    if (topic === 1) {
      this.showTopic1 = !this.showTopic1;
      this.showTopic2 = false;
      this.showTopic3 = false;
    } else if (topic === 2) {
      this.showTopic2 = !this.showTopic2;
      this.showTopic1 = false;
      this.showTopic3 = false;
    } else if (topic === 3) {
      this.showTopic3 = !this.showTopic3;
      this.showTopic1 = false;
      this.showTopic2 = false;
    }
  }

  playVideo(url: string): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  closeVideo() {
    this.videoUrl = null;
  }
}
