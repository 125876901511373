<!-- <app-main-header></app-main-header>
<br>
<div class="container">
  <div id="title">
    ABOUT US
  </div>
  <div class="container-sub1">
    <div id="support-message">
      <p>At VS Tech Horizon, guided by visionary Director Mr. Rushikesh Sule, our commitment to excellence and
        innovation fuels our cutting-edge technology solutions and fosters a collaborative, inclusive
        environment. We nurture talent, encourage creativity, and embrace diversity, shaping the future of
        technology education.</p>
      <p>VS Tech Horizon offers cutting-edge technology courses and hands-on experiences worldwide, guided by
        Director Mr. Rushikesh Sule. Committed to innovation and education, they shape a brighter tomorrow
        for aspiring technologists through dynamic workshops and comprehensive support services. They
        nurture talent, encourage creativity, and embrace diversity, shaping the future of technology
        education.</p>

    </div>
    <div id="education-img">
      <img src="../../assets/img/about us image.jpg" alt="">
    </div>
  </div>


</div>


<br><br><br>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer> -->



<app-main-header></app-main-header>

<div class="about-container">

  <div class="about-odd">
    <div class="length"></div>
    <h1>About Us</h1>
    <div class="about-odd-content">
      <div class="about-odd-para">
        <p>
          VS Tech Horizon Private Limited is at the for front of transforming education in India. We offer premier
          educational programs, comprehensive career guidance, and cutting-edge courses in emerging technologies
          like AI/ML. Our flagship initiatives, the VS Tech Horizon Scholarship Exam and the STEAM Connect Program
          are designed to bridge the gap between traditional education and modern industry demands. <br> <br>Under the
          visionary leadership of our director, Rushikesh Sule, we are dedicated to providing high-quality
          education that prepares students for success in a rapidly evolving technological landscape.
          We are committed to the transformative power of education and work tirelessly to ensure every student has
          access to essential resources. By fostering a supportive and innovative learning environment, we help students
          achieve their full potential and contribute to India's rapid growth and prosperity. Through our focused
          efforts, we aim to create an inclusive platform that empowers the next generation to excel and lead in their
          chosen fields. Join us at VS Tech Horizon and be a part of India's educational revolution.
        </p>
      </div>
      <div class="about-odd-img">
        <img src="../../assets/img/about (1).jpg" alt="">
      </div>
    </div>
  </div>
  <div class="about-even">
    <div class="length-1"></div>
    <div class="even-heading">
      <span>Our</span>
      <h1>Vision</h1>
    </div>
    <div class="about-even-content">
      <div class="about-even-img">
        <img src="../../assets/img/vision.webp" alt="" >
      </div>
      <div class="about-even-para">
        <p>
          Our vision at VS Tech Horizon is to democratize education by providing access to quality learning
          resources for all students. We aim to create an inclusive platform where everyone, regardless of their
          background, can learn, grow, and excel. <br><br> By fostering a culture of innovation and collaboration, we
          aspire to contribute to the rapid development of India, empowering the next generation to lead the
          nation towards a prosperous future.
        </p>
      </div>

    </div>
  </div>
  <div class="about-odd">
    <div class="length"></div>
    <div class="odd-heading">
      <span>Our</span>
      <h2>Mission</h2>
    </div>
   
    <div class="about-odd-content">
      <div class="about-odd-para">
        <p>
          Our mission is to transform education through innovative programs and initiatives that bridge the gap
          between traditional learning and modern technological advancements. We are dedicated to offering
          comprehensive educational programs, scholarships, and career guidance that equip students with the skills
          and knowledge they need to succeed in the 21st century. <br><br>By partnering with schools, colleges, and
          industry leaders, we strive to create opportunities for students to engage in hands-on learning
          experiences, gain practical skills, and achieve their full potential. Through our efforts, we aim to
          nurture a generation of well-rounded individuals who can contribute meaningfully to society and drive
          India’s growth on the global stage.
        </p>
      </div>
      <div class="about-odd-img">
        <img src="../../assets/img/mission.jpg" alt="" >
      </div>
    </div>
  </div>
</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
  <app-footer></app-footer>