// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-iot-project',
//   templateUrl: './iot-project.component.html',
//   styleUrls: ['./iot-project.component.scss']
// })
// export class IotProjectComponent {

// }
import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-iot-project',
  templateUrl: './iot-project.component.html',
  styleUrls: ['./iot-project.component.scss']
})
export class IotProjectComponent {

  constructor(private router: Router) { }
  courses = [
    {
      title: 'Smart City Lighting System using IoT',
      imgSrc: 'assets/iot projects/Smart City Lighting System using IoT.jpg',
      technology: "ESP32, MQTT, Nodejs.",
      fragment: 'smart-city-lighting'
    },
    {
      title: 'IoT based Smart Air Conditioner Control System',
      imgSrc: 'assets/iot projects/IoT based Predictive Maintenance System for Industrial Equipment.jpg',
      technology: "ESP32, MQTT, Nodejs.",
      showInfo: false,
      fragment: 'iot-based-smart-air'
    },
    {
      title: 'IoT based Smart Garment System',
      imgSrc: 'assets/iot projects/IoT based Smart Garment System.jpg',
      technology: "Arduino, Bluetooth Low Energy (BLE), Cloud-based Service",
      fragment: 'iot-based-smart-garment'
    },
    {
      title: 'Smart Solar Panel Management System using IoT',
      imgSrc: 'assets/iot projects/Smart Solar Panel Management System using IoT.jpg',
      technology: "Raspberry Pi, MQTT, Node.js",
      fragment: 'smart-solar-panel'
    },
    {
      title: 'IoT based Real-time Agriculture Monitoring and Control System',
      imgSrc: 'assets/iot projects/IoT based Real-time Agriculture Monitoring and Control System.jpg',
      technology: "ESP32, LoRa, Cloud-based Platform.",
      fragment: 'iot-based-real-time-agriculture'
    },
    {
      title: 'IoT based Smart Watering System for Gardens and Lawns',
      imgSrc: 'assets/iot projects/IoT based Smart Watering System for Gardens and Lawns.jpg',
      technology: "ESP8266, Soil Moisture Sensor, MQTT",
      fragment: 'iot-based-smart-watering'
    },
    {
      title: 'Smart Irrigation System with Soil Moisture Sensing using IoT',
      imgSrc: 'assets/iot projects/Smart Irrigation System with Soil Moisture Sensing using IoT.jpg',
      technology: "ESP32, Soil Moisture Sensor, MQTT",
      fragment: 'smart-irrigation-system'
    },
    {
      title: 'IoT based Smart Health Monitoring System for Elderly People',
      imgSrc: 'assets/iot projects/IoT based Smart Health Monitoring System for Elderly People.jpg',
      technology: "Raspberry Pi, Wearable Sensors, Cloud-based Platform ",
      fragment: 'iot-based-smart-health-monitoring'
    },
    {
      title: 'Smart Traffic Management System using IoT and AI',
      imgSrc: 'assets/iot projects/Smart Traffic Management System using IoT and AI.jpg',
      technology: "Raspberry Pi, Cameras, AI/ML Framework",
      fragment: 'smart-traffic-management'
    },
    {
      title: 'IoT based Indoor Air Quality Monitoring System',
      imgSrc: 'assets/iot projects/IoT based Indoor Air Quality Monitoring System.jpg',
      technology: "ESP32, Air Quality Sensors, MQTT",
      fragment: 'iot-based-indoor-air'
    },
  ];


  toggleInfo(course: any) {
    course.showInfo = !course.showInfo;
  }

  showPopup = false;
  name = '';
  message = '';


  showPopupBox() {
    this.showPopup = true;
    setTimeout(() => {
      const popupElement = document.querySelector('.popup');
      if (popupElement) {
        popupElement.classList.add('show');
      }
    }, 15);
  }

  hidePopup() {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.remove('show');
    }
    setTimeout(() => {
      this.showPopup = false;
    }, 500);
  }




  navigateToFragment(course: any) {
    this.router.navigate(['/iot'], { fragment: course.fragment });
  }
}