<app-main-header></app-main-header>
<div class="main">
    <div class="subject-info">
        <div class="sub-info">
            <div class="sub-name">
                <h1>C Programming Certification Course</h1>
    
                <div class="no">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="navy">
                        <path
                            d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                    <div class="mob">
                        <span>Have Queries? Ask us</span>
                        <h4>+91 9325360357</h4>
                    </div>
    
                </div>
            </div>
            <div class="details">
                <div class="video-overlay" *ngIf="videoUrl" (click)="closeVideo()">
                    <div class="video-container" (click)="$event.stopPropagation()">
                      <button class="close-button" (click)="closeVideo()">✖</button>
                      <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
                    </div>
                  </div>
                  <div class="image-container">
                    <img src="../../assets/img/c1.png" alt="" (click)="playVideo('https://www.youtube.com/embed/gGu82vH12eo')">
                    <div class="youtube-icon" (click)="playVideo('https://www.youtube.com/embed/gGu82vH12eo')">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
                        </svg>
                    </div>
                </div>
                <p>VS Tech Horizon C programming certification course is curated by industry experts to equip you with
                    the skills necessary to design and develop robust applications and softwares. This C programming
                    course is completely hands-on and you will get acquainted with core topics such as variables,
                    data types, functions, operators, control flow statements, Arrays, and get familiar with
                    advanced topics such as user-defined data types, pointers and memory allocation with industry
                    use cases. Enroll in this C programming online training to become a certified professional.</p>
            </div>
            <div class="info">
                <h2>Why should you take C Programming Certification Course?</h2>
                <div class="boxes">
                    <div class="box-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="navy">
                            <path
                                d="M0 224.2C0 100.6 100.2 0 224 0l24 0c95.2 0 181.2 69.3 197.3 160.2c2.3 13 6.8 25.7 15.1 36l42 52.6c6.2 7.8 9.6 17.4 9.6 27.4c0 24.2-19.6 43.8-43.8 43.8L448 320l0 64c0 35.3-28.7 64-64 64l-64 0 0 32c0 17.7-14.3 32-32 32L96 512c-17.7 0-32-14.3-32-32l0-72.7c0-16.7-6.9-32.5-17.1-45.8C16.6 322.4 0 274.1 0 224.2zM224 64c-8.8 0-16 7.2-16 16c0 33-39.9 49.5-63.2 26.2c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6C145.5 152.1 129 192 96 192c-8.8 0-16 7.2-16 16s7.2 16 16 16c33 0 49.5 39.9 26.2 63.2c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0C168.1 286.5 208 303 208 336c0 8.8 7.2 16 16 16s16-7.2 16-16c0-33 39.9-49.5 63.2-26.2c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6C302.5 263.9 319 224 352 224c8.8 0 16-7.2 16-16s-7.2-16-16-16c-33 0-49.5-39.9-26.2-63.2c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0C279.9 129.5 240 113 240 80c0-8.8-7.2-16-16-16zm-24 96a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm40 80a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
                        </svg>
                        <p>According to the TIOBE Index Sept 2021 report, C is the leading programming language among all
                            languages.</p>
                    </div>
                    <div class="box-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="navy">
                            <path d="M337.8 205.7l48.6-42.5c13.8 19.3 23.4 41.9 27.4 66.2l-64.4 4.3c-2.4-10.1-6.4-19.5-11.6-28zm140.1 19.5c-5.3-38.8-20.6-74.5-43.2-104.3l.8-.7C449 108.4 449.7 87.6 437 75s-33.4-12-45.2 1.5l-.7 .8c-29.8-22.6-65.5-37.9-104.3-43.2l.1-1.1c1.2-17.9-13-33-30.9-33s-32.1 15.2-30.9 33l.1 1.1c-38.8 5.3-74.5 20.6-104.3 43.2l-.7-.8C108.4 63 87.6 62.3 75 75s-12 33.4 1.5 45.2l.8 .7c-22.6 29.8-37.9 65.5-43.2 104.3l-1.1-.1c-17.9-1.2-33 13-33 30.9s15.2 32.1 33 30.9l1.1-.1c5.3 38.8 20.6 74.5 43.2 104.3l-.8 .7C63 403.6 62.3 424.4 75 437s33.4 12 45.2-1.5l.7-.8c29.8 22.6 65.5 37.9 104.3 43.2l-.1 1.1c-1.2 17.9 13 33 30.9 33s32.1-15.2 30.9-33l-.1-1.1c38.8-5.3 74.5-20.6 104.3-43.2l.7 .8c11.8 13.5 32.5 14.2 45.2 1.5s12-33.4-1.5-45.2l-.8-.7c22.6-29.8 37.9-65.5 43.2-104.3l1.1 .1c17.9 1.2 33-13 33-30.9s-15.2-32.1-33-30.9l-1.1 .1zM163.2 125.6c19.3-13.8 41.9-23.4 66.2-27.5l4.3 64.4c-10 2.4-19.5 6.4-28 11.6l-42.5-48.6zm-65 103.8c4.1-24.4 13.7-46.9 27.5-66.2l48.6 42.5c-5.3 8.5-9.2 18-11.6 28l-64.4-4.3zm27.5 119.4c-13.8-19.3-23.4-41.9-27.5-66.2l64.4-4.3c2.4 10 6.4 19.5 11.6 28l-48.6 42.5zm103.8 65c-24.4-4.1-46.9-13.7-66.2-27.4l42.5-48.6c8.5 5.3 18 9.2 28 11.6l-4.3 64.4zm119.4-27.4c-19.3 13.8-41.9 23.4-66.2 27.4l-4.3-64.4c10-2.4 19.5-6.4 28-11.6l42.5 48.6zm65-103.8c-4.1 24.4-13.7 46.9-27.4 66.2l-48.6-42.5c5.3-8.5 9.2-18 11.6-28l64.4 4.3zm-65-156.9l-42.5 48.6c-8.5-5.3-18-9.2-28-11.6l4.3-64.4c24.4 4.1 46.9 13.7 66.2 27.5zM256 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                        </svg>
                        <p>Telegram, GitHub, Oracle, Windows, Linux, Mozilla Firefox, and Mastercard include C in the tech
                            stack of their products.</p>
                    </div>
                    <div class="box-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="navy">
                            <path d="M64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l176 0-10.7 32L160 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l256 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-69.3 0L336 416l176 0c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0zM512 64l0 224L64 288 64 64l448 0z"/>
                        </svg>
                        <p>The average salary for a C developer is $91,851 per year in the US and $5,600 cash bonus per year
                            - Indeed.com.</p>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    <div class="course-details">
        <h2>Course Details</h2>
        <h1>C Programming Certification Course Details</h1>
        <div class="topic-1">
          <span class="top-1" (click)="toggleTopic(1)" >
            <svg *ngIf="!showTopic1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
            </svg>
            <svg *ngIf="showTopic1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232l144 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/>
            </svg>
            <h4>Basics of C Language and Flow Control</h4>
          </span>  
            <h3 *ngIf="showTopic1">Topics:</h3>
            <ul *ngIf="showTopic1">
                <li>First C program</li>
                <li>Functions</li>
                <li>Datatypes</li>
                <li>Control Flow</li>
                <li>Operators</li>
                <li>Number System</li>
            </ul>
            
        </div>
        <div class="topic-2">
            <span class="top-2" (click)="toggleTopic(2)">
                <svg *ngIf="!showTopic2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                </svg>
                <svg *ngIf="showTopic2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232l144 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/>
                </svg>
                <h4>Concepts of Array Memory and User-defined Datatypes</h4>
              </span>  
            <h3 *ngIf="showTopic2">Topics:</h3>
            <ul *ngIf="showTopic2">
                <li>Arrays</li>
                <li>Declaration of an Array</li>
                <li>Initialization of an Array</li>
                <li>Accessing Array Elements</li>
                <li>Memory Requirement</li>
                <li>Structures</li>
            </ul>
        </div>
        <div class="topic-3">
            <span class="top-3" (click)="toggleTopic(3)">
                <svg *ngIf="!showTopic3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                </svg>
                <svg *ngIf="showTopic3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232l144 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/>
                </svg>
                <h4>Understanding Functions in C</h4>
              </span>  
            <h3 *ngIf="showTopic3">Topics:</h3>
            <ul *ngIf="showTopic3">
                <li>Functions</li>
                <li>Why do we need Functions?</li>
                <li>Definition and Declaration of function</li>
                <li>Argument vs Parameters</li>
                <li>Calling a Function</li>
                <li>Inline functions
                </li>
            </ul>
        </div>
        
    </div>
</div>

<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>