<app-a-header></app-a-header>


<div>
    <h2>Contact</h2>
</div>

<table class="table table-bordered">
    <thead class="thead-light">
        <tr>
            <th scope="col">STEAM Registration Id</th>
            <th scope="col">Full Name</th>
            <th scope="col">School Name</th>
            <th scope="col">Standard</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Email</th>  
            <th scope="col">Date of Birth</th>
            <th scope="col">Payment Id</th>
            <th scope="col">Submit Date & Time</th>
        </tr>
    </thead>
    <!-- paymentId: -->
    <tbody>
        <tr *ngFor="let steamregistration of STEAMRegistration">
            <th>{{steamregistration.steamRegistrationId}}</th>
            <td>{{ steamregistration.name }}</td>
            <td>{{steamregistration.sname }}</td>
            <td>{{steamregistration.std }}</td>
            <td>{{steamregistration.number }}</td>
            <td>{{steamregistration.email }}</td>
            <td>{{steamregistration.dateOfBirth }}</td>
            <td>{{steamregistration.paymentId }}</td>
            <td>{{steamregistration.submitDate}}</td>
        </tr>
    </tbody>
</table>
