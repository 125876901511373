import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-career-guidance-sub',
  templateUrl: './career-guidance-sub.component.html',
  styleUrls: ['./career-guidance-sub.component.scss']
})
export class CareerGuidanceSubComponent {
  constructor(private router: Router, private dataService: DataService) { }
  applyNow(amount: number) {
    this.dataService.setAmount(amount);
    this.router.navigate(['/applycareerguidancefrom'], { state: { amount } });
    // this.router.navigate(['/applycareerguidancefrom'], { state: { amount } });
    // this.router.navigate(['/applycareerguidancefrom', { amount }]);
  }

//  // Track which form is currently open
//  openedFormTitle: string | null = null;

//  // Method to handle Apply Now button click
//  onApply(title: string) {
//    this.openedFormTitle = title;
//    console.log(title)
//  }

//  // Method to handle form submission
//  onSubmit(formData: any) {
//    console.log('Form submitted with data:', formData);
//    // Add logic to handle form submission (e.g., API call)
//  }
}
