<div id="f-container">

  <div id="f-box1">
    <a [routerLink]="'/'">
      <img id="vs-img" src="../../assets/image/vs tech logo.png" alt="">
    </a>
  </div>
  <div id="f-box2">
    <h3>CONTACT</h3>
    <p class="contac-p" style="align-items: center; "><svg style="height: 16px; width:16px; fill: white;"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
      </svg>
      &nbsp;&nbsp;vstechhorizon@gmail.com</p>
    <p class="contac-p"> <svg style="height: 16.8px; width: 16px; fill: white;" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path
          d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
      </svg> &nbsp;&nbsp;+91 93253 60357<p>
        <p class="contac-p"><svg style="height: 16.8px; width: 12px;fill: white;" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512">
            <path
              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
          </svg>&nbsp;&nbsp;Plot No.13 Gat No.574 <br> Sam Fransisco Park,<br>Matosh,Haveli,Kesnand <br>Pune, 412207</p>

  </div>
  <!-- <div id="f-box3">
    <h3>QUICK LINKS</h3>
    <div class="sub-f-box3">
      <p><a (click)="scrollToSection('services')">Services</a></p>
      <p><a [routerLink]="'/project'">Project</a></p>
      <p><a [routerLink]="'/scholarship'">Scholarship</a></p>
      <p><a [routerLink]="'/steam'">STEAM</a></p>
      <p><a (click)="scrollToSection('course')">Courses</a></p>
      <p><a [routerLink]="'/aboutus'">About</a></p>
      <p><a [routerLink]="'/form'">Contact Us</a></p>
      <p><a [routerLink]="'/career'">Career</a></p>

    </div>
  </div> -->
  <div id="f-box3">
    <h3>QUICK LINKS</h3>
    <div class="sub-f-box3">
      <p class="anchor"><a (click)="scrollToSection('services')">Services</a></p>
      <!-- <p><a [routerLink]="'/project'">Project</a></p> -->
      <!-- <div class="dropdown">
        <button class="dropbtn" (click)="toggleDropdown()">Projects</button>
        <div class="dropdown-content" [class.show]="isDropdownOpen">
          <a [routerLink]="'/it'">IT</a>
          <a [routerLink]="'/iot'">IOT</a>
          <a [routerLink]="'/ai-ml'">AI/ML</a>
          <a [routerLink]="'/robotics'">Robotics</a>
        </div>
      </div> -->
      <div class="dropdown">
        <!-- <button class="dropbtn" (click)="toggleDropdown()">Projects</button> -->
        <a class="anchor">Projects</a>
        <div class="dropdown-content">
          <a [routerLink]="'/it-project'">IT</a>
          <a [routerLink]="'/iot-project'">IOT</a>
          <a [routerLink]="'/ai-ml-project'">AI/ML</a>
          <a [routerLink]="'/robotic-project'">Robotics</a>
        </div>
      </div>

      <p class="anchor"><a [routerLink]="'/scholarship'">Scholarship</a></p>
      <p class="anchor"><a [routerLink]="'/steam'">STEAM</a></p>
      <p class="anchor"><a (click)="scrollToSection('course')">Courses</a></p>
      <p class="anchor"><a [routerLink]="'/aboutus'">About</a></p>
      <p class="anchor"><a [routerLink]="'/form'">Contact US</a></p>
      <p class="anchor"><a [routerLink]="'/career'">Career</a></p>

       
    </div>
      
  </div>
  <div id="f-box4">
    <h3>FOLLOW US</h3>
    <div id="f-box4-logo">
      <a href="https://www.instagram.com/vstechhorizon?igsh=MWtiMXI5NXpuZmozYQ==" target="_blank">
        <div>
          <svg height="50px" id="Layer_1" style="enable-background:new 0 0 1000 1000;" version="1.1"
            viewBox="0 0 1000 1000" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Instagram SVG code -->
            <style type="text/css">
              .st0 {
                fill: url(#SVGID_1_);
              }

              .st1 {
                fill: #FFFFFF;
              }

            </style>
            <linearGradient gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="146.4465" x2="853.5535" y1="853.5535"
              y2="146.4465">
              <stop offset="0" style="stop-color:#FFD521" />
              <stop offset="5.510481e-02" style="stop-color:#FFD020" />
              <stop offset="0.1241" style="stop-color:#FEC01E" />
              <stop offset="0.2004" style="stop-color:#FCA71B" />
              <stop offset="0.2821" style="stop-color:#FA8316" />
              <stop offset="0.3681" style="stop-color:#F85510" />
              <stop offset="0.4563" style="stop-color:#F51E09" />
              <stop offset="0.5" style="stop-color:#F30005" />
              <stop offset="0.5035" style="stop-color:#F20007" />
              <stop offset="0.5966" style="stop-color:#E1003B" />
              <stop offset="0.6879" style="stop-color:#D30067" />
              <stop offset="0.7757" style="stop-color:#C70088" />
              <stop offset="0.8589" style="stop-color:#BF00A0" />
              <stop offset="0.9357" style="stop-color:#BB00AF" />
              <stop offset="1" style="stop-color:#B900B4" />
            </linearGradient>
            <path class="st0"
              d="M500,1000L500,1000C223.9,1000,0,776.1,0,500v0C0,223.9,223.9,0,500,0h0c276.1,0,500,223.9,500,500v0  C1000,776.1,776.1,1000,500,1000z" />
            <g>
              <path class="st1"
                d="M500,220.2c91.1,0,101.9,0.3,137.9,2c33.3,1.5,51.4,7.1,63.4,11.8c15.9,6.2,27.3,13.6,39.2,25.5   c11.9,11.9,19.3,23.3,25.5,39.2c4.7,12,10.2,30.1,11.8,63.4c1.6,36,2,46.8,2,137.9s-0.3,101.9-2,137.9   c-1.5,33.3-7.1,51.4-11.8,63.4c-6.2,15.9-13.6,27.3-25.5,39.2c-11.9,11.9-23.3,19.3-39.2,25.5c-12,4.7-30.1,10.2-63.4,11.8   c-36,1.6-46.8,2-137.9,2s-101.9-0.3-137.9-2c-33.3-1.5-51.4-7.1-63.4-11.8c-15.9-6.2-27.3-13.6-39.2-25.5   c-11.9-11.9-19.3-23.3-25.5-39.2c-4.7-12-10.2-30.1-11.8-63.4c-1.6-36-2-46.8-2-137.9s0.3-101.9,2-137.9   c1.5-33.3,7.1-51.4,11.8-63.4c6.2-15.9,13.6-27.3,25.5-39.2c11.9-11.9,23.3-19.3,39.2-25.5c12-4.7,30.1-10.2,63.4-11.8   C398.1,220.5,408.9,220.2,500,220.2 M500,158.7c-92.7,0-104.3,0.4-140.7,2.1c-36.3,1.7-61.1,7.4-82.9,15.9   C254,185.3,234.9,197,216,216c-19,19-30.6,38-39.4,60.5c-8.4,21.7-14.2,46.5-15.9,82.9c-1.7,36.4-2.1,48-2.1,140.7   c0,92.7,0.4,104.3,2.1,140.7c1.7,36.3,7.4,61.1,15.9,82.9C185.3,746,197,765.1,216,784c19,19,38,30.6,60.5,39.4   c21.7,8.4,46.5,14.2,82.9,15.9c36.4,1.7,48,2.1,140.7,2.1s104.3-0.4,140.7-2.1c36.3-1.7,61.1-7.4,82.9-15.9   C746,814.7,765.1,803,784,784c19-19,30.6-38,39.4-60.5c8.4-21.7,14.2-46.5,15.9-82.9c1.7-36.4,2.1-48,2.1-140.7   s-0.4-104.3-2.1-140.7c-1.7-36.3-7.4-61.1-15.9-82.9C814.7,254,803,234.9,784,216c-19-19-38-30.6-60.5-39.4   c-21.7-8.4-46.5-14.2-82.9-15.9C604.3,159.1,592.7,158.7,500,158.7L500,158.7z" />
              <path class="st1"
                d="M500,324.7c-96.8,0-175.3,78.5-175.3,175.3S403.2,675.3,500,675.3S675.3,596.8,675.3,500   S596.8,324.7,500,324.7z M500,613.8c-62.8,0-113.8-50.9-113.8-113.8S437.2,386.2,500,386.2c62.8,0,113.8,50.9,113.8,113.8   S562.8,613.8,500,613.8z" />
              <circle class="st1" cx="682.2" cy="317.8" r="41" />
            </g>
          </svg>
        </div>
      </a>
      <a href="https://x.com/VS_tech_horizon?t=oUVS9u38vimS8IE9_seMMw&s=09" target="_blank">
        <img style="width: 50px; height: 50px; border-radius: 50%;" src="../../assets/img//twit.png" alt="">
        <!-- <div>
          <svg height="50px" enable-background="new 0 0 48 48" id="Layer_1" version="1.1" viewBox="0 0 48 48"
            xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            
            <circle cx="24" cy="24" fill="#1CB7EB" r="24" />
          <g>
            <g>
              <path
                d="M36.8,15.4c-0.9,0.5-2,0.8-3,0.9c1.1-0.7,1.9-1.8,2.3-3.1c-1,0.6-2.1,1.1-3.4,1.4c-1-1.1-2.3-1.8-3.8-1.8    c-2.9,0-5.3,2.5-5.3,5.7c0,0.4,0,0.9,0.1,1.3c-4.4-0.2-8.3-2.5-10.9-5.9c-0.5,0.8-0.7,1.8-0.7,2.9c0,2,0.9,3.7,2.3,4.7    c-0.9,0-1.7-0.3-2.4-0.7c0,0,0,0.1,0,0.1c0,2.7,1.8,5,4.2,5.6c-0.4,0.1-0.9,0.2-1.4,0.2c-0.3,0-0.7,0-1-0.1    c0.7,2.3,2.6,3.9,4.9,3.9c-1.8,1.5-4.1,2.4-6.5,2.4c-0.4,0-0.8,0-1.3-0.1c2.3,1.6,5.1,2.6,8.1,2.6c9.7,0,15-8.6,15-16.1    c0-0.2,0-0.5,0-0.7C35.2,17.6,36.1,16.6,36.8,15.4z"
                fill="#FFFFFF" />
            </g>
          </g>
          </svg>
        </div> -->
      </a>
      <a href="https://www.facebook.com/people/VS-Tech-Horizon/61558183835189/?mibextid=ZbWKwL" target="_blank">
        <div>
          <svg enable-background="new 0 0 512 512" height="51px" id="Layer_1" version="1.1" viewBox="0 0 512 512"
            width="51px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Facebook SVG code -->
            <g>
              <path clip-rule="evenodd"
                d="M256.23,512C396.81,512,512,396.81,512,256.23   C512,115.184,396.81,0,256.23,0C115.184,0,0,115.184,0,256.23C0,396.81,115.184,512,256.23,512L256.23,512z"
                fill="#3A5BA2" fill-rule="evenodd" />
              <path clip-rule="evenodd"
                d="M224.023,160.085c0-35.372,28.575-63.946,63.938-63.946h48.072   v63.946h-32.199c-8.608,0-15.873,7.257-15.873,15.873v32.192h48.072v63.938h-48.072v144.22h-63.938v-144.22h-48.065V208.15h48.065   V160.085z"
                fill="#FFFFFF" fill-rule="evenodd" />
            </g>
          </svg>
        </div>
      </a>
      <a href="https://www.linkedin.com/company/101767143/admin/inbox/" target="_blank">
        <div>
          <svg height="50px" id="Capa_1" style="enable-background:new 0 0 112.196 112.196;" version="1.1"
            viewBox="0 0 112.196 112.196" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- LinkedIn SVG code -->
            <g>
              <circle cx="56.098" cy="56.097" r="56.098" style="fill:#007AB9;" />
              <g>
                <path
                  d="M89.616,60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118    c-3.705,0-5.906,2.491-6.878,4.903c-0.353,0.862-0.444,2.059-0.444,3.268v22.524H48.684c0,0,0.18-36.546,0-40.329h13.411v5.715    c-0.027,0.045-0.065,0.089-0.089,0.132h0.089v-0.132c1.782-2.742,4.96-6.662,12.085-6.662    C83.002,42.462,89.616,48.226,89.616,60.611L89.616,60.611z M34.656,23.969c-4.587,0-7.588,3.011-7.588,6.967    c0,3.872,2.914,6.97,7.412,6.97h0.087c4.677,0,7.585-3.098,7.585-6.97C42.063,26.98,39.244,23.969,34.656,23.969L34.656,23.969z     M27.865,83.739H41.27V43.409H27.865V83.739z"
                  style="fill:#F1F2F2;" />
              </g>
            </g>
          </svg>
        </div>
      </a>
    </div>
  </div>


</div>

<div class="f-cr">
  <div class="subscribe">
    <h5>SUBSCRIBE</h5>

    <span>
      <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()">
        <input type="email" class="rounded-input" formControlName="email" id="email" #emailInput
          placeholder="Enter your Email..">
        <button type="submit" class="round-btn">Subscribe</button>
      </form>
    </span>
  
  </div>


</div>

<div id="terms-Policy-copy">

  <div id="sub-terms-Policy-copy">
    <a routerLink="/privacyandpolicy">Privacy Policy</a>
    <a routerLink="/termsandconditions">Terms & conditions</a>
    <a routerLink="/cancellationandrefund">Cancellation and Refund</a>
    <a routerLink="/shippinganddelivery">Shipping and Delivery</a>
  </div>

  <div id="sub-terms-Policy-copy-1">
    <div>&copy; VS Tech Horizon, All Right Reserved.</div>
  </div>
  <br>
  <br>
  <br>
</div>