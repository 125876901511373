
<app-main-header></app-main-header>
<div>
<div class="courses-container">
  <div class="courses-list" *ngFor="let course of courses" (mouseenter)="toggleInfo(course)"
    (mouseleave)="toggleInfo(course)">
    <img [src]="course.imgSrc" [alt]="course.title">
    <div class="info">
      <h3>{{ course.title }}</h3>

      <div class="enroll-div">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="grey">
          <path
            d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
        </svg> -->
        <p> <b>Technology:  </b>{{ course.technology }}</p>
      </div>
     
     

    <div class="info-popup" (click)="navigateToFragment(course)">
      <div class="popup-content">
        <h3>{{ course.title }}</h3>
        <p>Technology: {{ course.technology }}</p>
        <p><em>what will i learn?</em></p>
        <button >VIEW DETAILS</button>
        <!-- <p>{{ course.content }}</p> -->
      </div>
    </div>
  </div>
</div>
</div>
<!-- <app-video-modal #videoModal></app-video-modal> -->

<br><br>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>