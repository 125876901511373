<app-main-header></app-main-header>
<div class="courses-container">
  <div class="courses-list" *ngFor="let course of courses" (mouseenter)="toggleInfo(course)"
    (mouseleave)="toggleInfo(course)">
    <img [src]="course.imgSrc" [alt]="course.title">
    <div class="info">
      <h3>{{ course.title }}</h3>

      <div class="enroll-div">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="grey">
          <path
            d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
        </svg>
        <!-- <p> {{ course.enrolledLearners }}</p> -->
         <p>Get Ready!</p>
      </div>
      <div class="day-div">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="grey">
          <path
            d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z" />
        </svg>
        <p>{{ course.schedule }}</p>
      </div>
      <div class="video-div">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="grey">
          <path
            d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
        </svg>
        <p>{{ course.mode }}</p>
      </div>

      <div class="star-rating">
        <p><em>Reviews</em></p>
        <span *ngFor="let filled of getStarRating(course.rating)">
          <i class="fa fa-star" [class.filled]="filled"></i>
        </span>
        <span class="rating">{{course.rating}}</span>
      </div>
    </div>

    <div class="info-popup" (click)="navigateToCourse(course.title)">
      <div class="popup-content">
        <h3>{{ course.title }}</h3>
        <!-- <p>Enrolled Learners: {{ course.enrolledLearners }}</p> -->
         <p>Get Ready!</p>
        <p>Schedule: {{ course.schedule }}</p>
        <p>Mode: {{ course.mode }}</p>
        <p>Rating: {{ course.rating }}/5</p>
        <p><em>what will i learn?</em></p>
        <button >VIEW DETAILS</button>
        <!-- <p>{{ course.content }}</p> -->
      </div>
    </div>
  </div>
</div>

<!-- <app-video-modal #videoModal></app-video-modal> -->

<br><br>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>