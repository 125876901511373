import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentProtectionService {
  constructor() { }

  disableTextSelection() {
      document.body.style.userSelect = 'none'; // Disable text selection globally
      this.setupCopyPrevention();
  }

  private setupCopyPrevention() {
      document.addEventListener('keydown', (event: KeyboardEvent) => {
          if (event.ctrlKey && (event.key === 'c' || event.key === 'x')) {
              event.preventDefault();
              console.log('Copying content is not allowed.');
          }
      });
  }
}
