<app-main-header></app-main-header>
<div class="main_div">

  <h1>Explore Our Services</h1>



  <!-- <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
      aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
      aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
      aria-label="Slide 3"></button>
  </div> -->
  <!-- <div class="carousel-inner" style="height: 700px; width: 100%; border-radius: 10px; margin-top: 0;">
    <div class="carousel-item active">
      <img src="/assets/image/image/smart car.webp" height="700px" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div>
    </div>
    <div class="carousel-item ">
      <img src="/assets/image/image/Special-Education-Courses.jpg" height="700px" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/image/image/we-are-hiring.jpg" height="700px" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div>
    </div>
  </div> -->
  <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptionsFadeIndicatorsAutoplaying"
    data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptionsFadeIndicatorsAutoplaying"
    data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> -->

<!-- <div class="carousel-indicators">
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
    aria-current="true" aria-label="Slide 1"></button>
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
</div> -->
<div class="carousel-inner" style="height: 700px; width: 100%; border-radius: 50px;">
  <div class="carousel-item active">
    <img src="/assets/image/smart car.webp" height="700px" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
      <h5>Project kits and Supplies</h5>
      <p>Get Everything You Need to Start Learning.</p>
    </div>
  </div>

  <div class="carousel-item ">
    <img src="/assets/image/online-course1.jpg" height="700px" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
      <h5>Online Courses</h5>
      <p>Launch Anytime, Learn at Your Pace.</p>
    </div>
  </div>

  <div class="carousel-item">
    <img src="/assets/image/Test Preparation Programs.jpg" height="700px" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
      <h5>Test Preparation Programs</h5>
      <p>Ace Your Exams with Confidence.</p>
    </div>
  </div>

  <div class="carousel-item">
    <img src="/assets/image/Diversity and Inclusion Initiatives.jpg" height="700px" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
      <h5>Diversity and Inclusion Initiatives</h5>
      <p>Fostering a Welcoming Learning Environment.</p>
    </div>
  </div>

  <div class="carousel-item">
    <img src="/assets/image/Corporate Training and Professional Development.jpg" height="700px" class="d-block w-100"
      alt="...">
    <div class="carousel-caption d-none d-md-block">
      <h5>Corporate Training and Professional Development</h5>
      <p>Upskill Your Workforce and Drive Success.</p>
    </div>
  </div>
</div>
<!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptionsFadeIndicatorsAutoplaying"
  data-bs-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptionsFadeIndicatorsAutoplaying"
  data-bs-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="visually-hidden">Next</span>
</button> -->


<div style="display: flex; flex-wrap:wrap; justify-content: space-around;">
  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/online-course1.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <!-- <div class="a"> -->
    <h3 class="services_card_heading">Online Courses</h3>
    <!-- <img src="/assets/image/online-course1.jpg" style="object-fit: contain;"> -->
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Tutoring Services.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Tutoring Services</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Test Preparation Programs.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Test Preparation Programs</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Educational Resources.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Educational Resources</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Live Classes and Webinars.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Live Classes and Webinars</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Project kits and Supplies.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Project kits and Supplies</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Summer Camps and Workshops.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Summer Camps and Workshops</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Diversity and Inclusion Initiatives.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Diversity and Inclusion Initiatives</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Customized Learning Plans.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Customized Learning Plans</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Learning Management System (LMS).jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Learning Management System (LMS)</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Student Support Services.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Student Support Services</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Progress Tracking Reporting.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Progress Tracking Reporting</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Community Engagement.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Community Engagement</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Corporate Training and Professional Development.jpg'); background-size: cover; background-position:top; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Corporate Training and Professional Development</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Success Stories and Testimonials.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Success Stories and Testimonials</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/Partnerships and Accrediation.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <h3 class="services_card_heading">Partnerships and Accrediation</h3>
  </div>

  <div (click)="call_services()" class="a"
    style="background-image: url('/assets/image/FAQs and Support Documentation.jpg'); background-size: cover; background-position:center; background-repeat: no-repeat;">
    <h3 class="services_card_heading">FAQs and Support Documentation</h3>
  </div>

</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>