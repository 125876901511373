import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
@Component({
  selector: 'app-career-advice',
  templateUrl: './career-advice.component.html',
  styleUrls: ['./career-advice.component.scss']
})
export class CareerAdviceComponent implements OnInit {

  careerGuidance: any[] = [];


  private baseUrl = 'https://vstechhorizon.com:3006';

  constructor(private router: Router, private http: HttpClient, private authService: AuthService) { }
  ngOnInit() {

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.get<any[]>(`${this.baseUrl}/careerguidance`,{ headers: headers }).subscribe(
      data => {
        this.careerGuidance = data;
        console.log(this.careerGuidance); // Log the data after assignment
      },
      error => console.error('Error fetching resumes:', error)
    );
  }



}

