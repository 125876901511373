import { Component } from '@angular/core';

@Component({
  selector: 'app-shipping-and-delivery-policy',
  templateUrl: './shipping-and-delivery-policy.component.html',
  styleUrls: ['./shipping-and-delivery-policy.component.scss']
})
export class ShippingAndDeliveryPolicyComponent {

}
