
<app-main-header></app-main-header>
<div class="refund-policy">
    <h1>VS Tech Horizon Refund Policy</h1>
    <h2>Introduction</h2>
    <p>This refund policy outlines the terms and conditions under which refunds are processed for any payments made for projects or programs organized by VS Tech Horizon via the Razorpay payment gateway.</p>
  
    <h2>1. General Refund Policy</h2>
    <p><strong>No Refunds for Participant-Initiated Cancellations:</strong>  Payments made towards registrations for any projects or programs are final and non-refundable. Refund requests initiated by participants will not be entertained.</p>
  
    <h2>2. Refunds for Program/Project Cancellations by VS Tech Horizon</h2>
    <ul>
        <li>
    <p><strong>Full Refund:</strong>  In the unlikely event that a project or program is cancelled by us due to unforeseen circumstances, a full refund will be issued to the participants.</p>
</li>
<li>
     <strong>Refund Process:</strong>
</li>
    <ul>
      <li>If possible, refunds will be processed automatically back to the original payment method used during registration via Razorpay.</li>
      <li>If an automatic refund is not possible, you will receive an email with further instructions to facilitate the refund. In such cases, the refund will be processed after deducting Razorpay's processing charges.</li>
    </ul>
</ul>
  
    <h2>3. Processing Time</h2>
    <ul>
      <li> <strong>Automatic Refunds:</strong> Automatic Refunds: Refunds processed automatically will be initiated within 7-10 business days from the date of cancellation.</li>
      <li><strong>Manual Refunds:</strong> Refunds requiring manual processing will be initiated within 10-15 business days from the date of cancellation and may take additional time to reflect in your account depending on your bank’s policies.</li>
    </ul>
  
    <h2>4. Contact Information</h2>
    <p>For any queries or concerns regarding this refund policy, please contact our support team:</p>
    <p>VS Tech Horizon</p>
    <p>Email: <a href="mailto:support@vstechhorizon.com">support@vstechhorizon.com</a></p>
    <p>Phone: +91 9325360357</p>
  
    <h2>5. Amendments</h2>
    <p>VS Tech Horizon reserves the right to amend or modify this refund policy at any time. Participants will be notified of any changes via email or updates on our website.</p>
    <p>By registering for any projects or programs organized by VS Tech Horizon, you acknowledge that you have read, understood, and agree to this refund policy.</p>
  </div>
  <app-footer></app-footer>