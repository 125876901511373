<app-main-header></app-main-header>

<h2>Update Form</h2>
<!-- <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <div class="form-row">
            <label for="id">ID:</label>
            <input id="id" formControlName="id">
            <div
                *ngIf="updateForm.controls['id'].invalid && (updateForm.controls['id'].dirty || updateForm.controls['id'].touched)"class="error">
                <div *ngIf="updateForm.controls['id'].errors?.['required']">Id is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="title">Title:</label>
            <input id="title" formControlName="title">
            <div
                *ngIf="updateForm.controls['title'].invalid && (updateForm.controls['title'].dirty || updateForm.controls['title'].touched)"class="error">
                <div *ngIf="updateForm.controls['title'].errors?.['required']">Title is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="experience">Experience:</label>
            <input id="experience" formControlName="experience">
            <div
                *ngIf="updateForm.controls['experience'].invalid && (updateForm.controls['experience'].dirty || updateForm.controls['experience'].touched)"class="error">
                <div *ngIf="updateForm.controls['experience'].errors?.['required']">Experience is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="profile">Profile:</label>
            <input id="profile" formControlName="profile">
            <div
                *ngIf="updateForm.controls['profile'].invalid && (updateForm.controls['profile'].dirty || updateForm.controls['profile'].touched)"class="error">
                <div *ngIf="updateForm.controls['profile'].errors?.['required']">Profile is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="position">Position:</label>
            <input id="position" formControlName="position">
            <div
                *ngIf="updateForm.controls['position'].invalid && (updateForm.controls['position'].dirty || updateForm.controls['position'].touched)"class="error">
                <div *ngIf="updateForm.controls['position'].errors?.['required']">Position is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="opening">Opening:</label>
            <input id="opening" formControlName="opening">
            <div
                *ngIf="updateForm.controls['opening'].invalid && (updateForm.controls['opening'].dirty || updateForm.controls['opening'].touched)"class="error">
                <div *ngIf="updateForm.controls['opening'].errors?.['required']">Opening is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="experienceLevel">Experience Level:</label>
            <input id="experienceLevel" formControlName="experienceLevel">
            <div
                *ngIf="updateForm.controls['experienceLevel'].invalid && (updateForm.controls['experienceLevel'].dirty || updateForm.controls['experienceLevel'].touched)"class="error">
                <div *ngIf="updateForm.controls['experienceLevel'].errors?.['required']">Experience is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="skill">Skill:</label>
            <input id="skill" formControlName="skill">
            <div
                *ngIf="updateForm.controls['skill'].invalid && (updateForm.controls['skill'].dirty || updateForm.controls['skill'].touched)"class="error">
                <div *ngIf="updateForm.controls['skill'].errors?.['required']">Skill is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="location">Location:</label>
            <input id="location" formControlName="location">
            <div
                *ngIf="updateForm.controls['location'].invalid && (updateForm.controls['location'].dirty || updateForm.controls['location'].touched)"class="error">
                <div *ngIf="updateForm.controls['location'].errors?.['required']">Location is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="workDay">Work Day:</label>
            <input id="workDay" formControlName="workDay">
            <div
                *ngIf="updateForm.controls['workDay'].invalid && (updateForm.controls['workDay'].dirty || updateForm.controls['workDay'].touched)"class="error">
                <div *ngIf="updateForm.controls['workDay'].errors?.['required']">Work Day is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="time">Time:</label>
            <input id="time" formControlName="time">
            <div
                *ngIf="updateForm.controls['time'].invalid && (updateForm.controls['time'].dirty || updateForm.controls['time'].touched)"class="error">
                <div *ngIf="updateForm.controls['time'].errors?.['required']">Time is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="salary">Salary:</label>
            <input id="salary" formControlName="salary">
            <div
                *ngIf="updateForm.controls['salary'].invalid && (updateForm.controls['salary'].dirty || updateForm.controls['salary'].touched)"class="error">
                <div *ngIf="updateForm.controls['salary'].errors?.['required']">Salary is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="qualification">Qualification:</label>
            <input id="qualification" formControlName="qualification">
            <div
                *ngIf="updateForm.controls['qualification'].invalid && (updateForm.controls['qualification'].dirty || updateForm.controls['qualification'].touched)"class="error">
                <div *ngIf="updateForm.controls['qualification'].errors?.['required']">Qualification is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="roleOverview">Role Overview:</label>
            <textarea id="roleOverview" formControlName="roleOverview"></textarea>
            <div
                *ngIf="updateForm.controls['roleOverview'].invalid && (updateForm.controls['roleOverview'].dirty || updateForm.controls['roleOverview'].touched)"class="error">
                <div *ngIf="updateForm.controls['roleOverview'].errors?.['required']">Role Overview is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="roleAttribute">Role Attribute:</label>
            <textarea id="roleAttribute" formControlName="roleAttribute"></textarea>
            <div
                *ngIf="updateForm.controls['roleAttribute'].invalid && (updateForm.controls['roleAttribute'].dirty || updateForm.controls['roleAttribute'].touched)"class="error">
                <div *ngIf="updateForm.controls['roleAttribute'].errors?.['required']">Role Attribute is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="requiredSkill">Required Skill:</label>
            <input id="requiredSkill" formControlName="requiredSkill">
            <div
                *ngIf="updateForm.controls['requiredSkill'].invalid && (updateForm.controls['requiredSkill'].dirty || updateForm.controls['requiredSkill'].touched)"class="error">
                <div *ngIf="updateForm.controls['requiredSkill'].errors?.['required']">Required Skill is required</div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="additionalSkill">Additional Skill:</label>
            <input id="additionalSkill" formControlName="additionalSkill">
            <div
                *ngIf="updateForm.controls['additionalSkill'].invalid && (updateForm.controls['additionalSkill'].dirty || updateForm.controls['additionalSkill'].touched)"class="error">
                <div *ngIf="updateForm.controls['additionalSkill'].errors?.['required']">Additional Skill is required
                </div>
            </div>
        </div>

    </div>
    <div class="form-group">
        <div class="form-row">
            <label for="path">Path:</label>
            <input id="path" formControlName="path">
            <div
                *ngIf="updateForm.controls['path'].invalid && (updateForm.controls['path'].dirty || updateForm.controls['path'].touched)"class="error">
                <div *ngIf="updateForm.controls['path'].errors?.['required']">Path is required</div>
            </div>
        </div>
        
    </div>
    <button type="submit" [disabled]="updateForm.invalid">Update</button>
</form> -->

<form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
    <div>
      <label for="id">ID:</label>
      <input id="id" formControlName="id" [attr.disabled]="true">
    </div>
  
    <div>
      <label for="title">Title:</label>
      <input id="title" formControlName="title">
      <div *ngIf="updateForm.get('title')?.touched && updateForm.get('title')?.invalid">
        <small *ngIf="updateForm.get('title')?.hasError('required')">Title is required.</small>
      </div>
    </div>
  
    <div>
      <label for="experienceLevel">Experience Level:</label>
      <input id="experienceLevel" formControlName="experienceLevel">
      <div *ngIf="updateForm.get('experienceLevel')?.touched && updateForm.get('experienceLevel')?.invalid">
        <small *ngIf="updateForm.get('experienceLevel')?.hasError('required')">Experience Level is required.</small>
      </div>
    </div>
  
    <div>
      <label for="jobLocation">Job Location:</label>
      <input id="jobLocation" formControlName="jobLocation">
      <div *ngIf="updateForm.get('jobLocation')?.touched && updateForm.get('jobLocation')?.invalid">
        <small *ngIf="updateForm.get('jobLocation')?.hasError('required')">Job Location is required.</small>
      </div>
    </div>
  
    <div formGroupName="jobDescription">
      <div>
        <label for="companyProfile">Company Profile:</label>
        <input id="companyProfile" formControlName="companyProfile">
        <div *ngIf="updateForm.get('jobDescription.companyProfile')?.touched && updateForm.get('jobDescription.companyProfile')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.companyProfile')?.hasError('required')">Company Profile is required.</small>
        </div>
      </div>
  
      <div>
        <label for="position">Position:</label>
        <input id="position" formControlName="position">
        <div *ngIf="updateForm.get('jobDescription.position')?.touched && updateForm.get('jobDescription.position')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.position')?.hasError('required')">Position is required.</small>
        </div>
      </div>
  
      <div>
        <label for="currentOpening">Current Opening:</label>
        <input id="currentOpening" formControlName="currentOpening">
        <div *ngIf="updateForm.get('jobDescription.currentOpening')?.touched && updateForm.get('jobDescription.currentOpening')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.currentOpening')?.hasError('required')">Current Opening is required.</small>
        </div>
      </div>
  
      <div>
        <label for="primarySkill">Primary Skill:</label>
        <input id="primarySkill" formControlName="primarySkill">
        <div  *ngIf="updateForm.get('jobDescription')?.get('primarySkill')?.touched && updateForm.get('jobDescription')?.get('primarySkill')?.hasError('required')">
          <small>Primary Skill is required.</small>
        </div>
      </div>
  
      <div>
        <label for="workDays">Work Days:</label>
        <input id="workDays" formControlName="workDays">
        <div *ngIf="updateForm.get('jobDescription.workDays')?.touched && updateForm.get('jobDescription.workDays')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.workDays')?.hasError('required')">Work Days are required.</small>
        </div>
      </div>
  
      <div>
        <label for="workTimings">Work Timings:</label>
        <input id="workTimings" formControlName="workTimings">
        <div *ngIf="updateForm.get('jobDescription.workTimings')?.touched && updateForm.get('jobDescription.workTimings')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.workTimings')?.hasError('required')">Work Timings are required.</small>
        </div>
      </div>
  
      <div>
        <label for="salary">Salary:</label>
        <input id="salary" formControlName="salary">
        <div *ngIf="updateForm.get('jobDescription.salary')?.touched && updateForm.get('jobDescription.salary')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.salary')?.hasError('required')">Salary is required.</small>
        </div>
      </div>
  
      <div>
        <label for="educationalQualifications">Educational Qualifications:</label>
        <input id="educationalQualifications" formControlName="educationalQualifications">
        <div *ngIf="updateForm.get('jobDescription.educationalQualifications')?.touched && updateForm.get('jobDescription.educationalQualifications')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.educationalQualifications')?.hasError('required')">Educational Qualifications are required.</small>
        </div>
      </div>
  
      <div>
        <label for="roleOverview">Role Overview:</label>
        <input id="roleOverview" formControlName="roleOverview">
        <div *ngIf="updateForm.get('jobDescription.roleOverview')?.touched && updateForm.get('jobDescription.roleOverview')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.roleOverview')?.hasError('required')">Role Overview is required.</small>
        </div>
      </div>
  
      <div>
        <label for="requiredRoleAttributes">Required Role Attributes:</label>
        <input id="requiredRoleAttributes" formControlName="requiredRoleAttributes">
        <div *ngIf="updateForm.get('jobDescription.requiredRoleAttributes')?.touched && updateForm.get('jobDescription.requiredRoleAttributes')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.requiredRoleAttributes')?.hasError('required')">Required Role Attributes are required.</small>
        </div>
      </div>
  
      <div>
        <label for="requiredSkills">Required Skills:</label>
        <input id="requiredSkills" formControlName="requiredSkills">
        <div *ngIf="updateForm.get('jobDescription.requiredSkills')?.touched && updateForm.get('jobDescription.requiredSkills')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.requiredSkills')?.hasError('required')">Required Skills are required.</small>
        </div>
      </div>
  
      <div>
        <label for="additionalSkills">Additional Skills:</label>
        <input id="additionalSkills" formControlName="additionalSkills">
        <div *ngIf="updateForm.get('jobDescription.additionalSkills')?.touched && updateForm.get('jobDescription.additionalSkills')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.additionalSkills')?.hasError('required')">Additional Skills are required.</small>
        </div>
      </div>



      



      <div>
        <label for="careerPath">Career Path:</label>
        <input id="careerPath" formControlName="careerPath">
        <div *ngIf="updateForm.get('jobDescription.careerPath')?.touched && updateForm.get('jobDescription.careerPath')?.invalid">
          <small *ngIf="updateForm.get('jobDescription.careerPath')?.hasError('required')">Career Path is required.</small>
        </div>
      </div>
    </div>
  
    <button type="submit" [disabled]="updateForm.invalid">Update Job</button>
  </form>
  

<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>