import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataService {
 
  private amountSubject = new BehaviorSubject<number | null>(null);
  amount$ = this.amountSubject.asObservable();

  setAmount(amount: number): void {
    this.amountSubject.next(amount);
  }

  getAmount(): number | null {
    return this.amountSubject.value;
  }
}
