import { Component } from '@angular/core';

@Component({
  selector: 'app-kit-buy-form-success-dialog',
  templateUrl: './kit-buy-form-success-dialog.component.html',
  styleUrls: ['./kit-buy-form-success-dialog.component.scss']
})
export class KitBuyFormSuccessDialogComponent {

}
