// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class PaymentService {

//   constructor() { }
// }
// src/app/services/payment.service.ts

// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
// declare var Razorpay: any;
// @Injectable({
//   providedIn: 'root'
// })
// export class PaymentService {
//   private apiUrl = 'https://vstechhorizon.com:3006'; // Change this to your backend URL

//   constructor(private http: HttpClient) {}

//   createOrder(amount: number, currency: string): Observable<any> {
//     if(!amount){
//       window.alert("Error: Please select a plan and fill out all fields.");
//     }
//     return this.http.post<any>(`${this.apiUrl}/createOrder`, { amount, currency });
//   }

//   initiatePayment(orderId: string, amount: number, currency: string,name:string,email:String,mobile:String, callback: any) {
//     console.log(name,email,mobile)
//     const options: any = {
//       key: 'rzp_test_P6P49tf2uSj2ku', // Enter the Key ID generated from the Dashboard
//       amount: amount * 100, // Amount in paise
//       currency: currency,
//       order_id: orderId,
//       description: 'Sample Razorpay demo',
//       name: 'VS Tech Horizon',
//       image: '../../assets/img/vsTechLogoSq1.jpeg',
//       handler: function (response: any) {
//         callback(response);
//       },
//       prefill: {
//         name: name,
//         email: email,
//         contact: mobile
//       },
//       notes: {
//         address: 'Customer Address'
//       },
//       theme: {
//         color: '#F37254'
//       }
//     };

//     const rzp = new Razorpay(options);
//     rzp.open();
//   }
// }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
declare var Razorpay: any;
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = 'https://vstechhorizon.com:3006'; // Change this to your backend URL

  constructor(private http: HttpClient) {}

  createOrder(amount: number, currency: string): Observable<any> {
    if(!amount){
      window.alert("Error: Please select a plan and fill out all fields.");
    }
    return this.http.post<any>(`${this.apiUrl}/createOrder`, { amount, currency });
  }

  initiatePayment(orderId: string, amount: number, currency: string,name:string,email:String,mobile:String, callback: any) {
    console.log(name,email,mobile)
    const options: any = {
      key: 'rzp_live_cB11DRq7mavXTW', // Enter the Key ID generated from the Dashboard
      amount: 1 * 100, // Amount in paise
      currency: currency,
     
     
  
      order_id: orderId,
            description: 'Sample Razorpay demo',
      name: 'VS Tech Horizon',
      image: '../../assets/img/vsTechLogoSq1.jpeg',
      handler: function (response: any) {
        callback(response);
      },
      prefill: {
        name: name,
        email: email,
        contact: mobile
      },
      notes: {
        address: 'Customer Address'
      },
      theme: {
        color: '#F37254'
      }
    };

    const rzp = new Razorpay(options);
    rzp.open();
  }
}
