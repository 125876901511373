<app-a-header></app-a-header>


<div>
    <h2>Contact</h2>
</div>

<table class="table table-bordered">
    <thead class="thead-light">
        <tr>
            <th scope="col">Career Guidance Id</th>
            <th scope="col">Full Name</th>
            <th scope="col">Date of Birth</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Email</th>  
            <th scope="col">Gender</th>
            <th scope="col">Points to Discuss</th>
            <th scope="col">Payment Id</th>
            <th scope="col">Submit Date & Time</th>
        </tr>
    </thead>
    <!-- paymentId: -->
    <tbody>
        <tr *ngFor="let careerguidances of careerGuidance">
            <th>{{careerguidances.careerGuidanceId}}</th>
            <td>{{ careerguidances.fullName }}</td>
            <td>{{careerguidances.dob }}</td>
            <td>{{careerguidances.mobileNumber }}</td>
            <td>{{careerguidances.email }}</td>
            <td>{{careerguidances.gender }}</td>
            <td>{{careerguidances.discussionPoint }}</td>
            <td>{{careerguidances.paymentId }}</td>
            <td>{{careerguidances.submitDate}}</td>
        </tr>
    </tbody>
</table>
